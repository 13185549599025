import React, { forwardRef, useImperativeHandle, useState, useEffect, useRef } from 'react';

import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import './Custom.css'

import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux'
import { timeFormat, timeTable, domain } from "../../dummyData";

import {  deletePage, editPage, createPage } from "../../routes/PageAction";

import CustomAlert from '../Alert/CustomAlert';

const MemberPageModal = forwardRef((props, ref) => {
  const [show, setShow] = useState(false);

  useImperativeHandle(
    ref,
    () => ({
      handleOpen(data) {handleOpen(data)},
      handleClose() {handleClose()},
    }),
  )

  const handleOpen = (data) => {
    setPageData(data)
    setShow(true)
  };

  const handleClose = () => {
    setShow(false)
  };

  // console.log('d', props?.title)
  // console.log('allp', props)
  const dispatch = useDispatch();

  const customAlertRef = useRef()
  
  const navigate = useNavigate();
  const navigateToRoute = (url) => {
      navigate(url);
  };

  const [pageData, setPageData] = useState(null);


  return (
    <div onClick={() => handleClose}>
      <CustomAlert ref={customAlertRef}/>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          {/* <Modal.Title>{props?.eDate? timeTable(props?.eDate): 'View'}</Modal.Title> */}
          <Modal.Title>{props?.title?props?.title: 'View'}</Modal.Title>
        </Modal.Header>

        <Modal.Body>

          <div className="d-flex justify-content-center my-2 flex-column">
          {props.data?.length > 0 ? 
            props.data?.map((row) => (
              <div key={row.id} style={{backgroundColor:'#F9CC2E'}} className="d-flex flex-row justify-content-between px-1 py-1 my-1">
                              
                {/* <img alt='ddk' src={row?.user? domain+row?.user?.image:"./assets/images/1.jpg"} className="rounded-circle mt-1" height="40" width="40"></img> */}
                
                <div className="d-flex flex-column px-1 py-1">
                  <h6 style={{color:'#ec2f2f', fontSize:25, fontStyle:'italic'}} className="my-1">{row?.user? row?.user?.name:''}</h6>
                </div>
              </div>
            ))
                
            : 

            <h5 style={{color:'#ec2f2f', fontSize: 12, textAlign:'center'}}>No Users Yet</h5>}
          </div>

        </Modal.Body>

        <Modal.Footer>
          <div className=''>

            {/* <span onClick={submitData} style={{backgroundColor:'#ec2f2f', border:"solid 1px #ec2f2f"}} className="badge px-3 py-2 my-1 rounded-pill mr-2">
              Submit
            </span> */}
            <span style={{border:"solid 1px #ec2f2f", color:'#ec2f2f'}} className="badge px-3 py-2 my-1 rounded-pill" onClick={handleClose}>
              Cancel
            </span>

          </div>
        </Modal.Footer>
      </Modal>
    </div>
  );

})


export default MemberPageModal