import axios from 'axios'
import { createAsyncThunk } from '@reduxjs/toolkit';
import { domainUrl } from '../dummyData';

let url = 'https://emychatserver.herokuapp.com';
url = 'http://localhost:3003';
url = "http://127.0.0.1:8000/api"

url =  domainUrl

export const createNewUserDeleted= createAsyncThunk(
  'user_deleted/create',
  async (
    reqData, 
    { getState, rejectWithValue }) => {
    try {
      // get user data from store
      const { persist } = getState()
      
      // configure authorization header with user's token
      const config = {
        headers: {
          Authorization: `Bearer ${persist.user.userToken}`,
        },
      }

      // console.log('ax', firstName)
      const { data } = await axios.post(
        `${url}/deleteds`,
        reqData,
        config
      )

      return data

    } catch (error) {
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message)
      } else {
        return rejectWithValue(error.message)
      }
    }
  }
)

export const editUserDeleted= createAsyncThunk(
  'user_deleted/edit',
  async (
    reqData, 
    { getState, rejectWithValue }) => {
    try {
      // get user data from store
      const { persist } = getState()
      
      // configure authorization header with user's token
      const config = {
        headers: {
          Authorization: `Bearer ${persist.user.userToken}`,
        },
      }

      // console.log('ax', firstName)
      const { data } = await axios.put(
        `${url}/deleteds/${reqData.id}`,
        reqData,
        config
      )

      return data

    } catch (error) {
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message)
      } else {
        return rejectWithValue(error.message)
      }
    }
  }
)

export const deleteUserDeleted= createAsyncThunk(
  'user_deleted/delete',
  async (userDeletedId, { getState, rejectWithValue }) => {
    try {
      // get user data from store
      const { persist } = getState()
      
      // configure authorization header with user's token
      const config = {
        headers: {
          Authorization: `Bearer ${persist.user.userToken}`,
        },
      }

      const { data } = await axios.delete(`${url}/deleteds/${userDeletedId}`, config)
      return data
    } catch (error) {
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message)
      } else {
        return rejectWithValue(error.message)
      }
    }
  }
)

export const showUserDeleted= createAsyncThunk(
  'user_deleted/show',
  async (userDeletedId, { getState, rejectWithValue }) => {
    try {
      // get user data from store
      const { persist } = getState()
      
      // configure authorization header with user's token
      const config = {
        headers: {
          Authorization: `Bearer ${persist.user.userToken}`,
        },
      }

      const { data } = await axios.get(`${url}/deleteds/${userDeletedId}`, config)
      return data
    } catch (error) {
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message)
      } else {
        return rejectWithValue(error.message)
      }
    }
  }
)

export const getUserDeleted = createAsyncThunk(
  'user_deleted/get',
  async (arg, { getState, rejectWithValue }) => {
    try {
      // get user data from store
      const { persist } = getState()
      
      // configure authorization header with user's token
      const config = {
        headers: {
          Authorization: `Bearer ${persist.user.userToken}`,
        },
      }

      const { data } = await axios.get(`${url}/deleteds`, config)
      return data
    } catch (error) {
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message)
      } else {
        return rejectWithValue(error.message)
      }
    }
  }
)