import { configureStore } from '@reduxjs/toolkit';
import userReducer from './UserSlice'
import chatReducer from './ChatSlice'

import storage from "redux-persist/lib/storage";
import { persistReducer } from "redux-persist";
import { combineReducers } from "redux";

const persistConfig = {
  key: "drowswe-web-root",
  version: 1,
  storage: storage,
};

const reducer = combineReducers({
  user: userReducer,
});

const persistedReducer = persistReducer(persistConfig, reducer);

const reducer2 = {
  persist: persistedReducer,
  chat: chatReducer,
};

export const store = configureStore({
  // reducer: persistedReducer,
  reducer: reducer2,
  middleware: getDefaultMiddleware =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});
