import React, { forwardRef, useImperativeHandle, useState, useEffect, useRef } from 'react';

import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import './Custom.css'

import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux'
import { timeFormat, timeTable, domain } from "../../dummyData";

import {  deletePage, editPage, createPage } from "../../routes/PageAction";

import CustomAlert from '../Alert/CustomAlert';

const EditPageModal = forwardRef((props, ref) => {
  const [show, setShow] = useState(false);

  useImperativeHandle(
    ref,
    () => ({
      handleOpen() {handleOpen()},
      handleClose() {handleClose()},
    }),
  )

  const handleOpen = () => {
    setShow(true)
  };

  const handleClose = () => {
    setShow(false)
  };

  // console.log('d', props?.title)
  // console.log('allp', props)
  const dispatch = useDispatch();

  const customAlertRef = useRef()
  
  const navigate = useNavigate();
  const navigateToRoute = (url) => {
      navigate(url);
  };

  const [pageFormData, setPageFormData] = useState({
    id: props.data?.id,
    title: props.data?.title,
    about: props.data?.about,
    msg: props.data?.msg,
    image: props.data?.image,
    imagePreview: props.data?.image,
    terms: 0,
    total: '0.00',
    tax: '0.00',
    status: props.data?.status,
  });

  const handleFormChange = (e) => {
    if (e.target.name === 'status') {
      // console.log("Checkbox clicked, new status:", e.target.checked ? 1 : 0);
      setPageFormData({ ...pageFormData, [e.target.name]: e.target.checked ? 1 : 0 });
      // setPageFormData({ ...pageFormData, [e.target.name]: e.target.checked? 0: 1 });
    } else if(e.target.name === 'image') {

      const files = e.target.files;
      if (files && files.length > 0) {
          const file = files[0];
          setPageFormData({ ...pageFormData, [e.target.name]: file, imagePreview: file });
      } else {
          setPageFormData({ ...pageFormData, [e.target.name]: '', imagePreview: '' });
          console.error("No file selected");
      }
    } else {
      setPageFormData({ ...pageFormData, [e.target.name]: e.target.value });
    }
  }

  const submitData = () => {
    // console.log('newd', pageFormData)
    if(pageFormData.title === '') {
        return customAlertRef.current.handleOpen("Form filled incorrectly", 'danger');
    } else {
      return dispatch(editPage(pageFormData))
        .then(response =>{
          // if (error) {
          //   customAlertRef.current.handleOpen(error, 'danger');
          
          // } else {
          //     if (successMessage !== '') {
          //         customAlertRef.current.handleOpen(successMessage, 'success');
          //     }
          // }
          setShow(false);
        })
      .catch((error) => {
        console.log(error)
        customAlertRef.current.handleOpen(error, 'danger');
      })
    }
    
  }


  return (
    <div onClick={() => handleClose}>
      <CustomAlert ref={customAlertRef}/>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          {/* <Modal.Title>{props?.eDate? timeTable(props?.eDate): 'View'}</Modal.Title> */}
          <Modal.Title>{props?.title?props?.title: 'View'}</Modal.Title>
        </Modal.Header>

        <Modal.Body>

        {pageFormData?.imagePreview ? 
          (
            <img 
              style={{ border: '#16307D 3px solid', width: '10vw', height: '10vw', maxWidth: '30px', maxHeight: '30px' }} 
              alt='Preview' 
              src={pageFormData.imagePreview} 
              className="rounded-circle mx-auto mt-2 mb-1" 
            />
          ) : 
          (
            pageFormData?.image ? (
              <img 
                style={{ border: '#16307D 3px solid', width: '10vw', height: '10vw', maxWidth: '30px', maxHeight: '30px' }} 
                alt='ddk' 
                src={pageFormData?.image ? domain + pageFormData.image : "./assets/images/logo/logo.png"} 
                className="rounded-circle mx-auto mt-2 mb-1" 
              />
            ) : (
              <img 
                alt='ddk' 
                src="./assets/images/logo/logo.png"
                className="rounded-circle mx-auto mt-2 mb-1" 
                height="50" 
                width="50"
              />
            )
          )
        }

          <div className="d-flex justify-content-center my-2 flex-column">
            <input value={pageFormData.title} onChange={handleFormChange} className="form-control form-control-sm" required type="text" placeholder="Title" name='title'/> 

            <input onChange={handleFormChange} className="form-control form-control-sm mt-1" type="file" placeholder="image" name='image' required/>

            <input value={pageFormData.about? pageFormData.about: ''} onChange={handleFormChange} className="form-control form-control-sm mt-1" type="text" placeholder="about" name='about' required/>

            <input value={pageFormData.msg? pageFormData.msg: ''} onChange={handleFormChange} className="form-control form-control-sm mt-1" type="text" placeholder="Message" name='msg' required/>

            <div className='d-flex justify-content-center my-2 mx-auto flex-row'>
              <div className="form-check form-check-inline col-12">
                <input 
                  checked={pageFormData.status === 1}
                  onChange={handleFormChange} 
                  className="form-check-input" 
                  type="checkbox" 
                  id="inlineCheckbox1" 
                  value={pageFormData.status === 1 ? 1 : 0}
                  name='status'
                  style={{ borderColor: '#ec2f2f', boxShadow: '0 0 0 1px #ec2f2f', backgroundColor: '#ec2f2f' }}
                />
                <label className="form-check-label" htmlFor="inlineCheckbox1">Private</label>
              </div>
              {/* <div className="form-check form-check-inline col-6">
                <input checked={pageFormData.terms === 1? true: false} onChange={handleFormChange} className="form-check-input" type="checkbox" id="inlineCheckbox2" value="option2" name='terms'/>
                <label className="form-check-label" for="inlineCheckbox2">Trems</label>
              </div> */}
            </div>
            
          </div>

        </Modal.Body>

        <Modal.Footer>
          <div className=''>

            <span onClick={submitData} style={{backgroundColor:'#ec2f2f', border:"solid 1px #ec2f2f"}} className="badge px-3 py-2 my-1 rounded-pill mr-2">
              Submit
            </span>
            <span style={{border:"solid 1px #ec2f2f", color:'#ec2f2f'}} className="badge px-3 py-2 my-1 rounded-pill" onClick={handleClose}>
              Cancel
            </span>

          </div>
        </Modal.Footer>
      </Modal>
    </div>
  );

})


export default EditPageModal