import { createSlice } from '@reduxjs/toolkit';
import {
  getChatHistory,
  getUnreadChats,
  getMessage,
  sendMessage,
} from '../routes/ChatAction';


// initialize userToken from local storage
const userToken = localStorage.getItem('userToken')
  ? localStorage.getItem('userToken')
  : null

const initialState = {
  loading: false,
  userToken,
  error: null,
  success: false,
  successMessage: '',

  newChat: 0,
  currentType: '',
  currentId: 0,

  chats: null,

  chat: null,

  newMessages: [],
}

// initialState.blockedUsers = [1,2,6,7];
initialState.newMessages = [
  {chatId: 0, times: 0}
];

export const chatSlice = createSlice({
    name: 'chats',
    initialState,
    reducers: {
      setNewChatcount: (state, action) => {
        // console.log(action.payload);
        let newState = { ...state };
        let newValue = action.payload;
        newState.newChat = newValue;

        return newState;
      },
      setCurrentType: (state, action) => {
        // console.log(action.payload);
        let newState = { ...state };
        let newValue = action.payload;
        newState.currentType = newValue;

        return newState;
      },
      setCurrentId: (state, action) => {
        // console.log(action.payload);
        let newState = { ...state };
        let newValue = action.payload;
        newState.currentId = newValue;

        return newState;
      },

      selectChat: (state, action) => {
        console.log(action);
        let newState = { ...state };
        let index = newState.chats.map( el => el.id ).indexOf(action.payload);
        newState.chat = newState.chats[index];

        // fetch message here
        return newState;
      },
    },

    extraReducers: {

      // start
      // get unread chats
      [getUnreadChats.pending]: (state) => {
        let newState = { ...state };
        newState.error = null;
        newState.newChat = 0;
        newState.successMessage = '';
        newState.success = false;
        newState.loading = true;

        return newState;
      },
      [getUnreadChats.fulfilled]: (state, { payload }) => {
        let newState = { ...state };
        // console.log('pp', payload)
        newState.newChat = payload.data;
        newState.success = true;
        newState.loading = false;
        newState.successMessage = 'Done successfully!';

        return newState;
      },
      [getUnreadChats.rejected]: (state, { payload }) => {
        let newState = { ...state };
        newState.error = payload;
        newState.newChat = 0;
        newState.successMessage = '';
        newState.success = false;
        newState.loading = false;

        return newState;
      },

      // sendMessage
      [sendMessage.pending]: (state) => {
        let newState = { ...state };
        newState.error = null;
        newState.successMessage = '';
        newState.success = false;
        newState.loading = true;

        return newState;
      },
      [sendMessage.fulfilled]: (state, { payload }) => {
        // console.log(payload)
        let newState = { ...state };
        // newState.userPlayer = payload.user;
        newState.success = true;
        newState.loading = false;
        newState.successMessage = 'Done successfully!';

        return newState;
      },
      [sendMessage.rejected]: (state, { payload }) => {
        let newState = { ...state };
        newState.error = payload;
        newState.successMessage = '';
        newState.success = false;
        newState.loading = false;

        return newState;
      },

      // getMessage
      [getMessage.pending]: (state) => {
        let newState = { ...state };
        newState.error = null;
        newState.successMessage = '';
        newState.success = false;
        newState.loading = true;

        return newState;
      },
      [getMessage.fulfilled]: (state, { payload }) => {
        // console.log(payload)
        let newState = { ...state };
        newState.success = true;
        newState.loading = false;
        newState.successMessage = 'Done successfully!';

        let index = newState.chats.map( el => el.id ).indexOf(payload.chatId);
        newState.chat = newState.chats[index];
        // message read
        if(newState.newMessages.length > 0) {
          // if (newState.newMessages.includes(payload.user_id)) {
          if (newState.newMessages.map( el => { return el.id == payload.chatId}).length > 0){
            newState.newMessages = newState.newMessages.filter(item => item !== payload.chatId);
          }
        }

        return newState;
      },
      [getMessage.rejected]: (state, { payload }) => {
        let newState = { ...state };
        newState.error = payload;
        newState.successMessage = '';
        newState.success = false;
        newState.loading = false;

        return newState;
      },

      // getChatHistory
      [getChatHistory.pending]: (state) => {
        let newState = { ...state };
        newState.error = null;
        newState.successMessage = '';
        newState.success = false;
        newState.loading = true;

        return newState;
      },
      [getChatHistory.fulfilled]: (state, { payload }) => {
        // console.log(payload)
        let newState = { ...state };
        newState.chats = payload.chats;
        newState.success = true;
        newState.loading = false;
        newState.successMessage = 'Done successfully!';

        return newState;
      },
      [getChatHistory.rejected]: (state, { payload }) => {
        let newState = { ...state };
        newState.error = payload;
        newState.successMessage = '';
        newState.success = false;
        newState.loading = false;

        return newState;
      },

      // end
    },

  })

// Action creators are generated for each case reducer function
export const { selectChat, selectAlert, selectNotification, setNewChatcount, setCurrentId, setCurrentType } = chatSlice.actions

export default chatSlice.reducer