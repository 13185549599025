import React, { useState, useEffect } from "react"
import { useDispatch, useSelector } from 'react-redux'

import Header from '../../components/Header/Header';
import Footer from '../../components/Footer/Footer';

// import { checkout } from '../../routes/PaymentAction';
// import { getPackages } from "../../routes/PackageAction";

export default function Pricing() {
  
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.persist.user)
  const userData = user;

  const [pageData, setPageData] = useState([]);
  const [load, setLoad] = useState(false);

  const setLoadDiv = () => {
    setLoad(true)
    const timer = setTimeout(() => {
      setLoad(false)
    }, 3000); // 3000 milliseconds = 3 seconds

    return () => clearTimeout(timer);
  }

  const fetchPlans = () => {
    // dispatch(getPackages())
    // .then(response =>{
    //   let resData = response.payload.data;
    //   const editableData = resData.map(o => ({ ...o }));
    //   setPageData(editableData);
    // })
    // .catch((error) => {
    //     console.log(error)
    // })
  }

  const handlePayment = (id) => {
    // setLoadDiv();
    // setLoad(true)
    // dispatch(checkout({
    //   email: userData.email,
    //   type: 'subscription',
    //   paymentMode: 'card',
    //   id: id,
    // }))
    // .then(response =>{
    //   setLoad(false)
    //   console.log('res', response.payload.data)
    // })
    // .catch((error) => {
    //   setLoad(false)
    //     console.log(error)
    // })
  }

  const getColor = (num) => {
    if (num%2 === 0) {
      return 'card-header py-3 text-bg-primary border-primary'
    } else if (num%3 === 0) {
      return 'card-header py-3 text-bg-secondary border-secondary'
    } else if (num%5 === 0) {
      return 'card-header py-3 text-bg-success border-success'
    } else {
      return 'card-header py-3'
    }
  }

  useEffect(() => {

    fetchPlans();

  }, [])

  return (
    <>
      <Header/>
      <div className="divider"></div>

        {load ? (
          <div className="loader-container">
            <div className="spinner"></div>
            {/* <h6 style={{color: 'white', textAlign:'center'}}>loading please wait...</h6> */}
          </div>
        ) :
        <div className='mx-3'>
          <div className="row row-cols-1 row-cols-md-3 mb-3 text-center">

            {pageData?.map((row, index) => (
              <div key={row.id} className="col">
                <div className="card mb-4 rounded-3 shadow-sm border-primary">
                  <div className={getColor(index)}>
                    <h4 className="my-0 fw-normal">{row.name}</h4>
                  </div>
                  <div className="card-body">
                    <h1 className="card-title pricing-card-title">{row.user?.currency_symbol}{row.cost}<small className="text-body-secondary fw-light">/{row.duration_value > 0? (row.duration_value ===1 ? '': row.duration_value): ''} {row.duration}</small></h1>
                    <ul className="list-unstyled mt-3 mb-4">
                      {row.subPackages?.map((row) => (
                        <li key={row.id}>{row.name}</li>
                      ))}
                    </ul>
                    <button onClick={() => {handlePayment(row.id)}} type="button" className="w-100 btn btn-lg btn-primary">Pay Now</button>
                  </div>
                </div>
              </div>
            ))}



            {/* <div className="col">
              <div className="card mb-4 rounded-3 shadow-sm">
                <div className="card-header py-3">
                  <h4 className="my-0 fw-normal">Bronze</h4>
                </div>
                <div className="card-body">
                  <h1 className="card-title pricing-card-title">$0<small className="text-body-secondary fw-light">/mo</small></h1>
                  <ul className="list-unstyled mt-3 mb-4">
                    <li>Alert</li>
                    <li>SMS</li>
                  </ul>
                  <button type="button" className="w-100 btn btn-lg btn-outline-primary">Subscribe</button>
                </div>
              </div>
            </div>

            <div className="col">
              <div className="card mb-4 rounded-3 shadow-sm">
                <div className="card-header py-3">
                  <h4 className="my-0 fw-normal">Silver</h4>
                </div>
                <div className="card-body">
                  <h1 className="card-title pricing-card-title">$15<small className="text-body-secondary fw-light">/mo</small></h1>
                  <ul className="list-unstyled mt-3 mb-4">
                    <li>Alerts</li>
                    <li>SMS</li>
                    <li>Multi Session</li>
                  </ul>
                  <button type="button" className="w-100 btn btn-lg btn-primary">Subscribe</button>
                </div>
              </div>
            </div>

            <div className="col">
              <div className="card mb-4 rounded-3 shadow-sm border-primary">
                <div className="card-header py-3 text-bg-primary border-primary">
                  <h4 className="my-0 fw-normal">Gold</h4>
                </div>
                <div className="card-body">
                  <h1 className="card-title pricing-card-title">$29<small className="text-body-secondary fw-light">/mo</small></h1>
                  <ul className="list-unstyled mt-3 mb-4">
                  <li>Alerts</li>
                    <li>SMS</li>
                    <li>Multi Session</li>
                    <li>Visibility</li>
                  </ul>
                  <button onClick={handlePayment} type="button" className="w-100 btn btn-lg btn-primary">Pay Now</button>
                </div>
              </div>
            </div> */}
          </div>

        </div>
        }

      <Footer/>
    </>
  );
}
