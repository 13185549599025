import React, { useState, useEffect } from "react";
import CoachProfileBanner from "../../components/Banners/PageBanner";
import SlickHorizontalScroll from "../../components/Horizontal/SlickHorizontalScroll";
import { rowData, domainUrl } from "../../dummyData";
import Footer from "../../components/Footer/Footer"
import Header from "../../components/Header/Header";

import PageCard from "../../components/Cards/PageCard";
import PostCard from "../../components/Cards/PostCard";

import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux'
import { getPages, getPosts, getAds } from '../../routes/UserAction';
import { getUnreadChats } from '../../routes/ChatAction';



const UserDashboard = () => {

    const dispatch = useDispatch();
    const { user, loading, searchWord } = useSelector((state) => state.persist.user)
    const userData = user;
    const pages = userData?.pages;

    const [pageData, setPageData] = useState([]);
    const [postData, setPostData] = useState([]);

    const [pageNo, setPageNo] = useState(1);

    const handleLoadPlus = () => { 
        setPageNo(pageNo+1); 
    };
    const handleLoadMinus = () => { 
        setPageNo(pageNo >1? pageNo-1: 1); 
    };

    const fetchUnread = () => {
        dispatch(getUnreadChats());
        // .then(response =>{})
    }

    const fetchPage = () => {
        dispatch(getPages({cat: 'main', searchword: searchWord, page:pageNo}))
        .then(response =>{
          let resData = response?.payload?.data? response?.payload?.data: [];
          const editableData = resData.map(o => ({ ...o }));
          setPageData(editableData);
    
          // console.log('res', response.payload.message)
          // console.log('res', response.payload.success)
          // console.log('res', response.payload.data)
        })
        .catch((error) => {
            console.log(error)
        })
    }

    const fetchPost = () => {
        dispatch(getPosts({cat: 'main', searchword: searchWord, page:pageNo}))
        .then(response =>{
          let resData = response?.payload?.data? response?.payload?.data:[];
          const editableData = resData.map(o => ({ ...o }));
          setPostData(editableData);
        })
        .catch((error) => {
            console.log(error)
        })
    }

    // const handleSearch = (word) => {
    //     dispatch(updateKeyWord(word))
    // }

    useEffect(() => {

        fetchPage();
        fetchPost();

        fetchUnread ();

        // console.log('key', searchWord)
    
    }, [searchWord])

    return(
        <>
            <div className="divider"></div>
            <Header page={'home'}/>

            {/* {(loading || sLoading)? ( */}
            {loading ? (
                <div className="loader-container">
                    <div className="spinner"></div>
                    {/* <h6 style={{color: 'white', textAlign:'center'}}>loading please wait...</h6> */}
                </div>
            ) :<>
            <div>
                <h6 style={{color:'#ec2f2f', marginTop:'1px', marginBottom:'0', marginLeft:"4px"}}>Pages</h6>
                <hr className="" style={{color:'#ec2f2f', backgroundColor:'#ec2f2f', height:'2px', marginTop:'1px', marginBottom:'5',}}/>

                {/* {pageData?  */}
                <div className="px-5">
                    <SlickHorizontalScroll slidesToShow={pageData?.length<=3? (pageData?.length) : null} data={pageData} card={PageCard}/>
                </div>
                {/* : <></>} */}
            </div>
            <div>
                <h6 style={{color:'#ec2f2f', marginTop:'1px', marginBottom:'0', marginLeft:"4px"}}>New Posts</h6>
                <hr className="" style={{color:'#ec2f2f', backgroundColor:'#ec2f2f', height:'2px', marginTop:'1px', marginBottom:'5',}}/>

                {/* {postData?  */}
                <div className="px-5">
                    <SlickHorizontalScroll slidesToShow={postData?.length<=3? (postData?.length) : null} data={postData} card={PostCard}/>
                </div>
                {/* : <></>} */}
            </div>

            </>}

            <Footer/>
        </>
        
    )
}

export default UserDashboard