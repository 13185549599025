import "./App.css"
import HomePage from "./home/HomePage"
import { BrowserRouter as Router, Routes, Route, Navigate } from "react-router-dom"

import LoginPage from "./home/LoginPage"
import SignUp from "./home/RegisterPage"
import ForgotPassword from "./home/ForgetPasswordPage"
import VerifyCodePage from "./home/ResetCodePage"
import ResetPassword from "./home/ResetPasswordPage"
import EditProfile from "./home/EditProfilePage"
import GhanabaShare from "./home/SharePage"

import Checkout from "./Pages/Checkout/Checkout"
import Pricing from "./Pages/Checkout/Pricing"
import Contact from "./Pages/Auth/ContactUs"

import CheckoutForm from "./Pages/Checkout/CheckoutForm"
import PaymentResponse from "./Pages/Checkout/PaymentResponse"
import AccountResponse from "./Pages/Checkout/AccountResponse"
import PaymentResponseF from "./Pages/Checkout/PaymentResponseF"
import NotActive from "./Pages/Checkout/NotActive"

import LandingPage from "./Pages/Users/LandingPage"
import UserPages from "./Pages/Users/UserPages"
import UserPosts from "./Pages/Users/UserPosts"
import Service from "./Pages/Users/Services"
import Chat from "./Pages/Users/Chat"
import UserPost from "./Pages/Users/UserPost"
import UserPage from "./Pages/Users/UserPage"

import NotFound from "./Pages/Auth/NotFound"

import ProtectedRoute from './routes/ProtectedRoute'

function App() {

  return (
    <Router>
      <Routes>
        <Route exact path='/' element={<HomePage />} />
      
        <Route path='/login' element={<LoginPage />} />
        <Route path='/register' element={<SignUp />} />
        <Route path='/reset_password' element={<ResetPassword />} />
        <Route path='/forgot_password' element={<ForgotPassword />} />
        <Route path='/verify_code' element={<VerifyCodePage />} />
        <Route path='/payment_response' element={<PaymentResponse />} />
        <Route path='/payment_response_fail' element={<PaymentResponseF />} />
        <Route path='/account_response' element={<AccountResponse />} />
        
        <Route element={<ProtectedRoute />}>
          <Route exact path='/contact' element={<Contact />} />

          <Route path='/edit_profile' element={<EditProfile />} />
          <Route path='/ghana_ba_shares' element={<GhanabaShare />} />
          <Route exact path='/pricing' element={<Pricing />} />
          <Route path='/checkout' element={<CheckoutForm />} />
        
          <Route exact path='/page_profile' element={<UserPage/>} />
          <Route exact path='/landing_page' element={<LandingPage/>} />
          <Route exact path='/post_profile' element={<UserPost/>} />
          
          <Route exact path='/pages' element={<UserPages/>} />
          <Route exact path='/posts' element={<UserPosts/>} />
          <Route exact path='/service' element={<Service/>} />
          <Route exact path='/chat' element={<Chat/>} />

          <Route exact path='/post' element={<UserPost/>} />
          <Route exact path='/page' element={<UserPage/>} />
        

        </Route>
        
        <Route path="*" element={<NotFound/>} />
      </Routes>
    </Router>
  );
}

export default App
