import React, { useState, useEffect } from "react";
import PageBanner from "../../components/Banners/PageBanner";
import PageBannerMy from "../../components/Banners/PageBannerMy";
import SlickHorizontalScroll from "../../components/Horizontal/SlickHorizontalScroll";
import Footer from "../../components/Footer/Footer"
import Header from "../../components/Header/Header";

import PageCard from "../../components/Cards/PageCard";
import PostCard from "../../components/Cards/PostCard";


import { Link, useNavigate, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux'
import { getPages, getPosts, showPage } from '../../routes/UserAction';


const UserCoach = () => {

    const dispatch = useDispatch();
    const search = useLocation().search;
    const id =new URLSearchParams(search).get("id");
    // const userId =new URLSearchParams(search).get("code");

    const { user, searchWord, loading } = useSelector((state) => state.persist.user)
    const userData = user;

    // const sessionSli = useSelector((state) => state.session)
    // const sLoading = sessionSli.loading;
    // const chatSli = useSelector((state) => state.chat)
    // const cLoading = chatSli.loading;
    // const sysSli = useSelector((state) => state.system)
    // const sysLoading = sysSli.loading;

    const [pageData, setPageData] = useState({});
    const [pagesData, setPagesData] = useState([]);
    const [postsData, setPostsData] = useState([]);
    const [isMine, setIsMine] = useState(false);

    const [pageNo, setPageNo] = useState(1);
    const handleLoadPlus = () => { 
        setPageNo(pageNo+1); 
    };
    const handleLoadMinus = () => { 
        setPageNo(pageNo >1? pageNo-1: 1); 
    };

    const fetchPages = () => {
        dispatch(getPages({cat: 'single', searchword: searchWord, page:pageNo}))
        .then(response =>{
          let resData = response.payload.data;
          const editableData = resData.map(o => ({ ...o }));
          setPagesData(editableData);
        })
        .catch((error) => {
            console.log(error)
        })
    }

    const fetchPosts = () => {
        dispatch(getPosts({cat: 'single', searchword: searchWord, page:pageNo}))
        .then(response =>{
          let resData = response.payload.data;
          const editableData = resData.map(o => ({ ...o }));
          setPostsData(editableData);
        })
        .catch((error) => {
            console.log(error)
        })
    }

    const fetchPage = () => {
        dispatch(showPage(id))
        .then(response =>{
          setPageData(response.payload.data);

          if (userData.id === response.payload.data?.user?.id) {
            setIsMine(true)
          }
        })
        .catch((error) => {
            console.log(error)
        })
    }

    useEffect(() => {

        fetchPage();
        fetchPages();
        fetchPosts();
    
    }, [id])

    return(
        <>
            <div className="divider"></div>
            <Header page={'page'}/>

            {(loading) ? (
                <div className="loader-container">
                    <div className="spinner"></div>
                    {/* <h6 style={{color: 'white', textAlign:'center'}}>loading please wait...</h6> */}
                </div>
            ) :<>

                {isMine === true? <PageBannerMy fetchPage={fetchPage} data={pageData}/> : <PageBanner fetchPage={fetchPage} data={pageData}/>}

                {/* <div className="d-flex justify-content-center my-2">
                    {pageData?.sessions?
                    <div className="d-flex flex-row">
                        {pageData.expertise?.length > 0 ?
                            pageData.expertise?.map((row) => (
                                <span key={row.id} style={{marginRight:'5px', backgroundColor:'#E1F2FE', color:'#ec2f2f', border:"solid 1px #ec2f2f"}} className="badge py-2 my-1 rounded-pill">{row.expertise.name}</span>
                            ))
                        : <h5 className="my-3" style={{color:'#ec2f2f', fontSize: 12}}>No Expertise...</h5>}
                    </div>
                    : <></>}
                </div> */}

                <h6 style={{textAlign:'center', color:'#ec2f2f', marginTop:'1px', marginBottom:'0', marginLeft:"4px"}}>{user.description}</h6>

                {/* {isMine === false? */}
                <div>
                    <h6 style={{color:'#ec2f2f', marginTop:'1px', marginBottom:'0', marginLeft:"4px"}}>Posts</h6>
                    <hr className="" style={{color:'#ec2f2f', backgroundColor:'#ec2f2f', height:'2px', marginTop:'1px', marginBottom:'5',}}/>

                    <div className="px-5">
                        <SlickHorizontalScroll slidesToShow={pageData?.posts?.filter(el => (el.status !== 5))?.length<=3? (pageData?.posts?.filter(el => (el.status !== 5))?.length) : null} data={pageData?.posts?.filter(el => (el.status !== 5))} card={PostCard}/>
                    </div>
                </div>

                {/* <div>
                    <h6 style={{color:'#ec2f2f', marginTop:'1px', marginBottom:'0', marginLeft:"4px"}}>Similar Pages</h6>
                    <hr className="" style={{color:'#ec2f2f', backgroundColor:'#ec2f2f', height:'2px', marginTop:'1px', marginBottom:'5',}}/>

                    <div className="px-5">
                        <SlickHorizontalScroll slidesToShow={pagesData?.length<=3? (pagesData?.length) : null} data={pagesData} card={PageCard}/>
                    </div>
                </div> */}
                {/* :<></>} */}

                <div>
                    <h6 style={{color:'#ec2f2f', marginTop:'1px', marginBottom:'0', marginLeft:"4px"}}>Similar Pages</h6>
                    <hr className="" style={{color:'#ec2f2f', backgroundColor:'#ec2f2f', height:'2px', marginTop:'1px', marginBottom:'5',}}/>

                    <div className="px-5">
                        <SlickHorizontalScroll slidesToShow={pagesData?.filter(el => (el.id !== pageData.id))?.length<=3? (pagesData?.filter(el => (el.id !== pageData.id))?.length) : null} data={pagesData?.filter(el => (el.id !== pageData.id))} card={PageCard}/>
                    </div>
                </div>

                
            </>}

            <Footer/>
        </>
        
    )
}

export default UserCoach