import React, { forwardRef, useImperativeHandle, useState, useEffect, useRef } from 'react';

import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import './Custom.css'

import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux'
import { timeFormat, timeTable, domain } from "../../dummyData";

import {  deletePage, editPage, createPage } from "../../routes/PageAction";

import CustomAlert from '../Alert/CustomAlert';

const JoinPageModal = forwardRef((props, ref) => {
  const [show, setShow] = useState(false);

  useImperativeHandle(
    ref,
    () => ({
      handleOpen() {handleOpen()},
      handleClose() {handleClose()},
    }),
  )

  const handleOpen = () => {
    setShow(true)
  };

  const handleClose = () => {
    setShow(false)
  };

  // console.log('d', props?.title)
  // console.log('allp', props)
  const dispatch = useDispatch();

  const customAlertRef = useRef()
  
  const navigate = useNavigate();
  const navigateToRoute = (url) => {
      navigate(url);
  };

  const [pageFormData, setpageFormData] = useState({
    account_no: '',
    page_id: 0,
    name: '',
    account_type: '',
    terms: 0,
    total: '0.00',
    tax: '0.00',
    status: 0,
  });

  const handleFormChange = (e) => {
    if (e.target.name === 'terms') {
      setpageFormData({ ...pageFormData, [e.target.name]: e.target.checked? 0: 1 });
    }  else {
      setpageFormData({ ...pageFormData, [e.target.name]: e.target.value });
    }
  }

  const submitData = () => {
    // console.log('newd', pageFormData)
    if(pageFormData.title === '') {
        return customAlertRef.current.handleOpen("Form filled incorrectly", 'danger');
    } else {
      return dispatch(createPage(pageFormData))
        .then(response =>{
          // if (error) {
          //   customAlertRef.current.handleOpen(error, 'danger');
          
          // } else {
          //     if (successMessage !== '') {
          //         customAlertRef.current.handleOpen(successMessage, 'success');
          //     }
          // }
        })
      .catch((error) => {
        console.log(error)
        customAlertRef.current.handleOpen(error, 'danger');
      })
    }
    
  }


  return (
    <div onClick={() => handleClose}>
      <CustomAlert ref={customAlertRef}/>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          {/* <Modal.Title>{props?.eDate? timeTable(props?.eDate): 'View'}</Modal.Title> */}
          <Modal.Title>{props?.title?props?.title: 'View'}</Modal.Title>
        </Modal.Header>

        <Modal.Body>

          <div className="d-flex justify-content-center my-2 flex-column">
          <div className="mb-3">
              <select value={pageFormData.page_id? pageFormData.page_id: ''} name='page_id' onChange={handleFormChange} className="form-select form-select-sm" aria-label=".form-select-sm example">
                  <option value="">Select Investment Area</option>
                  <option value="1">Agro Secure</option>
                  <option value="2">Mining</option>
              </select>
            </div>

            <input value={pageFormData.title} onChange={handleFormChange} className="form-control form-control-sm" required type="text" placeholder="Name" name='name'/> 

            <div className="mb-3">
              <select value={pageFormData.account_type? pageFormData.account_type: ''} name='account_type' onChange={handleFormChange} className="form-select form-select-sm" aria-label=".form-select-sm example">
                  <option value="">Select Payment</option>
                  <option value="momo">MTN (MOMO)</option>
                  <option value="bank">Bank</option>
              </select>
            </div>

            <input value={pageFormData.account_no? pageFormData.account_no: ''} onChange={handleFormChange} className="form-control form-control-sm mt-1" type="text" placeholder="Account No" name='account_no' required/>

            <div className='d-flex justify-content-center my-2 mx-auto flex-row'>
              
              <div className="form-check form-check-inline col-6">
                <input checked={pageFormData.terms === 1? true: false} onChange={handleFormChange} className="form-check-input" type="checkbox" id="inlineCheckbox2" value="option2" name='terms'/>
                <label className="form-check-label" for="inlineCheckbox2">Agreed to conditions</label>
              </div>
            </div>
            
          </div>

        </Modal.Body>

        <Modal.Footer>
          <div className=''>

            <span onClick={submitData} style={{backgroundColor:'#ec2f2f', border:"solid 1px #ec2f2f"}} className="badge px-3 py-2 my-1 rounded-pill mr-2">
              Submit
            </span>
            <span style={{border:"solid 1px #ec2f2f", color:'#ec2f2f'}} className="badge px-3 py-2 my-1 rounded-pill" onClick={handleClose}>
              Cancel
            </span>

          </div>
        </Modal.Footer>
      </Modal>
    </div>
  );

})


export default JoinPageModal