import { createSlice } from '@reduxjs/toolkit';
import { 
  signIn, 
  logout, 
  signUp,
  editUser,
  getUsers,
  deleteAccount,
  showAccount,
  getPages,
  getPosts,
  updatePost,
  updatePage,
  getPost,
  getPage,
  showPost,
  showPage,
  contact,
  deleteStripeAccount,
  forgotPassword,
  verifyCode,
  resetPassword,
  // stripeAccount,
} from '../routes/UserAction';

// initialize userToken from local storage
const userToken = localStorage.getItem('userToken')
  ? localStorage.getItem('userToken')
  : null

const initialState = {
  loading: false,
  userToken,
  error: null,
  success: false,
  successMessage: '',

  searchWord: '',

  users: null,

  allUsers: null,

  post: null,
  page: null,

  posts: null,
  pages: null,
  
  userPage: null,
  userPost: null,
  user: null,
}

export const userSlice = createSlice({
    name: 'users',
    initialState,
    reducers: {
      updateProg: (state, action) => {
        let newState = { ...state };
        newState.error = null;
        newState.successMessage = '';
        newState.success = false;
        newState.loading = false;

        return newState;
      },

      updateKeyWord: (state, action) => {
        // console.log('action', action.payload);
        let newState = { ...state };
        let newWord = action.payload;
        newState.searchWord = newWord;
        return newState;
      },

      
    },

    extraReducers: {

      // edit user
      [editUser.pending]: (state) => {
        let newState = { ...state };
        newState.error = null;
        newState.successMessage = '';
        newState.success = false;
        newState.loading = true;

        return newState;
      },
      [editUser.fulfilled]: (state, { payload }) => {
        let newState = { ...state };

        newState.user = payload.user;
        newState.success = true;
        newState.loading = false;
        newState.userToken = payload.token;
        newState.successMessage = 'Updated successfully!';
        
        return newState;
      },
      [editUser.rejected]: (state, { payload }) => {
        let newState = { ...state };
        newState.error = payload;
        newState.successMessage = '';
        newState.success = false;
        newState.loading = false;

        return newState;
      },


      // forgotPassword
      [forgotPassword.pending]: (state) => {
        let newState = { ...state };
        newState.error = null;
        newState.successMessage = '';
        newState.success = false;
        newState.loading = true;

        return newState;
      },
      [forgotPassword.fulfilled]: (state, { payload }) => {
        let newState = { ...state };
        newState.error = null;
        newState.success = true;
        newState.loading = false;
        newState.successMessage = 'Email sent successfully';

        return newState;
      },
      [forgotPassword.rejected]: (state, { payload }) => {
        let newState = { ...state };
        newState.error = payload;
        newState.successMessage = '';
        newState.success = false;
        newState.loading = false;

        return newState;
      },
      // verifyCode
      [verifyCode.pending]: (state) => {
        let newState = { ...state };
        newState.error = null;
        newState.successMessage = '';
        newState.success = false;
        newState.loading = true;

        return newState;
      },
      [verifyCode.fulfilled]: (state, { payload }) => {
        let newState = { ...state };
        newState.error = null;
        newState.success = true;
        newState.loading = false;
        newState.successMessage = 'Email sent successfully';

        return newState;
      },
      [verifyCode.rejected]: (state, { payload }) => {
        let newState = { ...state };
        newState.error = payload;
        newState.successMessage = '';
        newState.success = false;
        newState.loading = false;

        return newState;
      },
      // resetPassword
      [resetPassword.pending]: (state) => {
        let newState = { ...state };
        newState.error = null;
        newState.successMessage = '';
        newState.success = false;
        newState.loading = true;

        return newState;
      },
      [resetPassword.fulfilled]: (state, { payload }) => {
        let newState = { ...state };
        newState.error = null;
        newState.success = true;
        newState.loading = false;
        newState.successMessage = 'Email sent successfully';

        return newState;
      },
      [resetPassword.rejected]: (state, { payload }) => {
        let newState = { ...state };
        newState.error = payload;
        newState.successMessage = '';
        newState.success = false;
        newState.loading = false;

        return newState;
      },

      
      // login user
      [signIn.pending]: (state) => {
        state.loading = true
        state.error = null
      },
      [signIn.fulfilled]: (state, { payload }) => {
        let newState = { ...state };

        newState.user = payload.user;
        newState.success = true;
        newState.loading = false;
        newState.userToken = payload.token;
        
        return newState;
      },
      [signIn.rejected]: (state, { payload }) => {
        // console.log('pload', payload)
        state.loading = false
        state.error = payload
      },

      // register user
      [signUp.pending]: (state) => {
        let newState = { ...state };
        newState.error = null;
        newState.successMessage = '';
        newState.success = false;
        newState.loading = true;

        return newState;
      },
      [signUp.fulfilled]: (state, { payload }) => {
        // console.log(payload)
        let newState = { ...state };
        // newState.user = payload.user;
        newState.success = true;
        newState.loading = false;
        // newState.userToken = payload.token;
        newState.successMessage = 'Registered successfully!';

        return newState;
      },
      [signUp.rejected]: (state, { payload }) => {
        let newState = { ...state };
        newState.error = payload;
        newState.successMessage = '';
        newState.success = false;
        newState.loading = false;

        return newState;
      },

      // contact
      [contact.pending]: (state) => {
        let newState = { ...state };
        newState.error = null;
        newState.successMessage = '';
        newState.success = false;
        newState.loading = true;

        return newState;
      },
      [contact.fulfilled]: (state, { payload }) => {
        // console.log(payload)
        let newState = { ...state };
        newState.success = true;
        newState.loading = false;
        newState.successMessage = 'Sent successfully!';

        return newState;
      },
      [contact.rejected]: (state, { payload }) => {
        let newState = { ...state };
        newState.error = payload;
        newState.successMessage = '';
        newState.success = false;
        newState.loading = false;

        return newState;
      },
      
      // get users
      [getUsers.pending]: (state) => {
        state.loading = true
      },
      [getUsers.fulfilled]: (state, { payload }) => {
        let newState = { ...state };
        newState.users = payload.users;
        // newState.success = true;
        newState.loading = false;
        
        return newState;
      },
      [getUsers.rejected]: (state, { payload }) => {
        state.loading = false
      },
      // logout
      [logout.pending]: (state) => {
        state.loading = true
      },
      [logout.fulfilled]: (state, { payload }) => {
        localStorage.removeItem('userToken') // delete token from storage
        localStorage.clear();
        // window.location.href = "/login";

        let newState = { ...state };
        newState.user = null;
        // newState.success = true;
        newState.loading = false;
        newState.userToken = null;
        
        return newState;
      },
      [logout.rejected]: (state, { payload }) => {
        state.loading = false
        
        localStorage.removeItem('userToken') // delete token from storage
        localStorage.clear();
        // window.location.href = "/login";
      },

      // deleteAccount
      [deleteAccount.pending]: (state) => {
        let newState = { ...state };
        newState.error = null;
        newState.successMessage = '';
        newState.success = false;
        newState.loading = true;

        return newState;
      },
      [deleteAccount.fulfilled]: (state, { payload }) => {
        // console.log(payload)
        let newState = { ...state };
        localStorage.removeItem('userToken') // delete token from storage
        newState.user = null;
        newState.loading = false;
        newState.userToken = null;
        newState.successMessage = 'Done successfully!';

        return newState;
      },
      [deleteAccount.rejected]: (state, { payload }) => {
        let newState = { ...state };
        newState.error = payload;
        newState.successMessage = '';
        newState.success = false;
        newState.loading = false;

        return newState;
      },

      // showAccount
      [showAccount.pending]: (state) => {
        let newState = { ...state };
        newState.error = null;
        newState.successMessage = '';
        newState.success = false;
        newState.loading = true;

        return newState;
      },
      [showAccount.fulfilled]: (state, { payload }) => {
        // console.log(payload)
        let newState = { ...state };
        newState.user = payload.user;
        newState.success = true;
        newState.loading = false;
        newState.successMessage = 'Done successfully!';

        return newState;
      },
      [showAccount.rejected]: (state, { payload }) => {
        let newState = { ...state };
        newState.error = payload;
        newState.successMessage = '';
        newState.success = false;
        newState.loading = false;

        return newState;
      },

      // getPages
      [getPages.pending]: (state) => {
        let newState = { ...state };
        newState.error = null;
        newState.successMessage = '';
        newState.success = false;
        newState.loading = true;

        return newState;
      },
      [getPages.fulfilled]: (state, { payload }) => {
        // console.log(payload)
        let newState = { ...state };
        newState.pages = payload.user;
        newState.success = true;
        newState.loading = false;
        newState.successMessage = 'Done successfully!';

        return newState;
      },
      [getPages.rejected]: (state, { payload }) => {
        let newState = { ...state };
        newState.error = payload;
        newState.successMessage = '';
        newState.success = false;
        newState.loading = false;

        return newState;
      },

      // getPosts
      [getPosts.pending]: (state) => {
        let newState = { ...state };
        newState.error = null;
        newState.successMessage = '';
        newState.success = false;
        newState.loading = true;

        return newState;
      },
      [getPosts.fulfilled]: (state, { payload }) => {
        // console.log(payload)
        let newState = { ...state };
        newState.posts = payload.data;
        newState.success = true;
        newState.loading = false;
    
        newState.successMessage = 'Done successfully!';

        return newState;
      },
      [getPosts.rejected]: (state, { payload }) => {
        let newState = { ...state };
        newState.error = payload;
        newState.successMessage = '';
        newState.success = false;
        newState.loading = false;

        return newState;
      },

      // updatePost
      [updatePost.pending]: (state) => {
        let newState = { ...state };
        newState.error = null;
        newState.successMessage = '';
        newState.success = false;
        newState.loading = true;

        return newState;
      },
      [updatePost.fulfilled]: (state, { payload }) => {
        // console.log(payload)
        let newState = { ...state };
        newState.userpost = payload.user;
        newState.success = true;
        newState.loading = false;
        newState.successMessage = 'Done successfully!';

        return newState;
      },
      [updatePost.rejected]: (state, { payload }) => {
        let newState = { ...state };
        newState.error = payload;
        newState.successMessage = '';
        newState.success = false;
        newState.loading = false;

        return newState;
      },

      // updatePage
      [updatePage.pending]: (state) => {
        let newState = { ...state };
        newState.error = null;
        newState.successMessage = '';
        newState.success = false;
        newState.loading = true;

        return newState;
      },
      [updatePage.fulfilled]: (state, { payload }) => {
        // console.log(payload)
        let newState = { ...state };
        newState.userPage = payload.user;
        newState.success = true;
        newState.loading = false;
        newState.successMessage = 'Done successfully!';

        return newState;
      },
      [updatePage.rejected]: (state, { payload }) => {
        let newState = { ...state };
        newState.error = payload;
        newState.successMessage = '';
        newState.success = false;
        newState.loading = false;

        return newState;
      },

      // getPost
      [getPost.pending]: (state) => {
        let newState = { ...state };
        newState.error = null;
        newState.successMessage = '';
        newState.success = false;
        newState.loading = true;

        return newState;
      },
      [getPost.fulfilled]: (state, { payload }) => {
        // console.log(payload)
        let newState = { ...state };
        newState.userPost = payload.user;
        newState.success = true;
        newState.loading = false;
        newState.successMessage = 'Done successfully!';

        return newState;
      },
      [getPost.rejected]: (state, { payload }) => {
        let newState = { ...state };
        newState.error = payload;
        newState.successMessage = '';
        newState.success = false;
        newState.loading = false;

        return newState;
      },

      // getPage
      [getPage.pending]: (state) => {
        let newState = { ...state };
        newState.error = null;
        newState.successMessage = '';
        newState.success = false;
        newState.loading = true;

        return newState;
      },
      [getPage.fulfilled]: (state, { payload }) => {
        // console.log(payload)
        let newState = { ...state };
        newState.userPage = payload.user;
        newState.success = true;
        newState.loading = false;
        newState.successMessage = 'Done successfully!';

        return newState;
      },
      [getPage.rejected]: (state, { payload }) => {
        let newState = { ...state };
        newState.error = payload;
        newState.successMessage = '';
        newState.success = false;
        newState.loading = false;

        return newState;
      },

      // showPost
      [showPost.pending]: (state) => {
        let newState = { ...state };
        newState.error = null;
        newState.successMessage = '';
        newState.success = false;
        newState.loading = true;

        return newState;
      },
      [showPost.fulfilled]: (state, { payload }) => {
        // console.log(payload)
        let newState = { ...state };
        newState.userPost = payload.data;
        newState.success = true;
        newState.loading = false;
        newState.successMessage = 'Done successfully!';

        return newState;
      },
      [showPost.rejected]: (state, { payload }) => {
        let newState = { ...state };
        newState.error = payload;
        newState.successMessage = '';
        newState.success = false;
        newState.loading = false;

        return newState;
      },

      // showPage
      [showPage.pending]: (state) => {
        let newState = { ...state };
        newState.error = null;
        newState.successMessage = '';
        newState.success = false;
        newState.loading = true;

        return newState;
      },
      [showPage.fulfilled]: (state, { payload }) => {
        // console.log(payload)
        let newState = { ...state };
        newState.userPage = payload.data;
        newState.success = true;
        newState.loading = false;
        newState.successMessage = 'Done successfully!';

        return newState;
      },
      [showPage.rejected]: (state, { payload }) => {
        let newState = { ...state };
        newState.error = payload;
        newState.successMessage = '';
        newState.success = false;
        newState.loading = false;

        return newState;
      },


      // delete stripe account
      [deleteStripeAccount.pending]: (state) => {
        let newState = { ...state };
        newState.error = null;
        newState.successMessage = '';
        newState.success = false;
        newState.loading = true;

        return newState;
      },
      [deleteStripeAccount.fulfilled]: (state, { payload }) => {
        // console.log(payload)
        let newState = { ...state };
        newState.success = true;
        newState.loading = false;
        newState.successMessage = 'Done successfully!';

        return newState;
      },
      [deleteStripeAccount.rejected]: (state, { payload }) => {
        let newState = { ...state };
        newState.error = payload;
        newState.successMessage = '';
        newState.success = false;
        newState.loading = false;

        return newState;
      },
      
      // end
    },

  })

// Action creators are generated for each case reducer function
export const { updateKeyWord, updateProg } = userSlice.actions

export default userSlice.reducer