import axios from 'axios'
import { createAsyncThunk } from '@reduxjs/toolkit';
import { domainUrl } from '../dummyData';

let url = 'https://emychatserver.herokuapp.com';
url = 'http://localhost:3003';
url = "http://127.0.0.1:8000/api"

url =  domainUrl


export const deletePay= createAsyncThunk(
  'user/delete_payment_acct',
  async (
    reqData, 
    { getState, rejectWithValue }) => {
    try {
      // get user data from store
      const { persist } = getState()
      
      // configure authorization header with user's token
      const config = {
        headers: {
          Authorization: `Bearer ${persist.user.userToken}`,
        },
      }

      const { data } = await axios.post(
        `${url}/delete_payment_account`,
        reqData,
        config
      )

      return data.data

    } catch (error) {
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message)
      } else {
        return rejectWithValue(error.message)
      }
    }
  }
)

export const signIn = createAsyncThunk(
  'user/login',
  async (
    { 
      email, 
      password 
    }, 
    { rejectWithValue }) => {
    try {
      // configure header's Content-Type as JSON
      const config = {
        headers: {
          'Content-Type': 'application/json',
        },
      }

      const { data } = await axios.post(
        `${url}/login`,
        { email, password },
        config
      )

      // store user's token in local storage
      localStorage.setItem('userToken', data.data.token)

      return data.data
    } catch (error) {
      // return custom error message from API if any
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message)
      } else {
        return rejectWithValue(error.message)
      }
    }
  }
)

export const editUser= createAsyncThunk(
  'user/edit',
  async (
    reqData, 
    { getState, rejectWithValue }) => {
    try {
      // get user data from store
      const { persist } = getState()
      
      // configure authorization header with user's token
      const config = {
        headers: {
          Authorization: `Bearer ${persist.user.userToken}`,
          'Content-Type': 'multipart/form-data',
        },
      }

      // console.log('ax', firstName)
      // const { data } = await axios.put(
      //   `${url}/users/${reqData.id}`,
      //   reqData,
      //   config
      // )
      const { data } = await axios.post(
        `${url}/profile_update`,
        reqData,
        config
      )

      // return data

      localStorage.setItem('userToken', data.data.token)
      // console.log(data.data.token)

      return data.data

      // localStorage.setItem('userToken', data.data.token)
      // // return data
      // return {
      //   user: data.data,
      //   message: data.message,
      //   success:data.success,
      //   token: data.token
      // }

    } catch (error) {
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message)
      } else {
        return rejectWithValue(error.message)
      }
    }
  }
)

export const signUp = createAsyncThunk(
  'user/register',
  async (
    { 
      name,
      email,
      phone,
      password,
      long,
      lat,
      voterId,
    }, 
    { rejectWithValue }) => {
    try {
      const config = {
        headers: {
          'Content-Type': 'application/json',
        },
      }

      // console.log('ax', firstName)
      const { data } = await axios.post(
        `${url}/register`,
        { 
          name,
          email,
          phone,
          password,
          long,
          lat,
          voterId,
        },
        config
      )
      
      // window.location.href = "/login";
      // console.log('axd', data)
      // localStorage.setItem('userToken', data.data.token)
      // return data
      return {
        user: data.data,
        message: data.message,
        success:data.success,
        token: data.token
      }

    } catch (error) {
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message)
      } else {
        return rejectWithValue(error.message)
      }
    }
  }
)

export const logout = createAsyncThunk(
  'user/logout',
  async (arg, { getState, rejectWithValue }) => {
    try {
      // get user data from store
      const { persist } = getState()

      // console.log('emjay')
      // console.log(getState())

      // configure authorization header with user's token
      const config = {
        headers: {
          Authorization: `Bearer ${persist.user.userToken}`,
        },
      }
      // console.log('logout')

      const { data } = await axios.get(`${url}/logout`, config)

      return data
    } catch (error) {
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message)
      } else {
        return rejectWithValue(error.message)
      }
    }
  }
)

export const getPages = createAsyncThunk(
  'user/all_pages',
  async (reqData, { getState, rejectWithValue }) => {
    try {
      // get user data from store
      const { persist } = getState()
      
      // configure authorization header with user's token
      const config = {
        headers: {
          Authorization: `Bearer ${persist.user.userToken}`,
        },
      }

      const { data } = await axios.get(
        `${url}/pages?cat=${reqData.cat}&&searchword=${reqData.searchword}&&page=${reqData.page}`,
        config
      )
      return data
    } catch (error) {
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message)
      } else {
        return rejectWithValue(error.message)
      }
    }
  }
)

export const getPosts = createAsyncThunk(
  'user/all_posts',
  async (reqData, { getState, rejectWithValue }) => {
    try {
      // get user data from store
      const { persist } = getState()
      
      // configure authorization header with user's token
      const config = {
        headers: {
          Authorization: `Bearer ${persist.user.userToken}`,
        },
      }

      const { data } = await axios.get(`${url}/d_posts?cat=${reqData.cat}&&searchword=${reqData.searchword}&&page=${reqData.page}`, config)
      return data
    } catch (error) {
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message)
      } else {
        return rejectWithValue(error.message)
      }
    }
  }
)

export const updatePost = createAsyncThunk(
    'user/edit_post',
    async ({msg, to_user_id, message_type, from_user_id}, { getState, rejectWithValue }) => {
      try {
        // get user data from store
      const { persist } = getState()
      
      // configure authorization header with user's token
      const config = {
        headers: {
          Authorization: `Bearer ${persist.user.userToken}`,
        },
      }
  
        const { data } = await axios.post(
          `${url}/user/edit_post`,
          {msg, to_user_id, message_type, from_user_id},
          config
        )
  
        return data
      } catch (error) {
        if (error.response && error.response.data.message) {
          return rejectWithValue(error.response.data.message)
        } else {
          return rejectWithValue(error.message)
        }
      }
    }
)

export const updatePage = createAsyncThunk(
  'user/edit_page',
  async ({msg, to_user_id, message_type, from_user_id}, { getState, rejectWithValue }) => {
    try {
      // get user data from store
    const { persist } = getState()
    
    // configure authorization header with user's token
    const config = {
      headers: {
        Authorization: `Bearer ${persist.user.userToken}`,
      },
    }

      const { data } = await axios.post(
        `${url}/user/edit_page`,
        {msg, to_user_id, message_type, from_user_id},
        config
      )

      return data
    } catch (error) {
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message)
      } else {
        return rejectWithValue(error.message)
      }
    }
  }
)

export const getPost = createAsyncThunk(
    'user/get_Post',
    async ({ user_id }, { getState, rejectWithValue }) => {
      try {
        // get user data from store
        const { user } = getState()
  
        // configure authorization header with user's token
        const config = {
          headers: {
            Authorization: `Bearer ${user.userToken}`,
          },
        }
  
        const { data } = await axios.get(`${url}/user/get_post/${user_id}`, config)

        return {
          data: data,
          user_id: user_id
        }
      } catch (error) {
        if (error.response && error.response.data.message) {
          return rejectWithValue(error.response.data.message)
        } else {
          return rejectWithValue(error.message)
        }
      }
    }
)

export const getPage = createAsyncThunk(
  'user/get_page',
  async ({ user_id }, { getState, rejectWithValue }) => {
    try {
      // get user data from store
      const { user } = getState()

      // configure authorization header with user's token
      const config = {
        headers: {
          Authorization: `Bearer ${user.userToken}`,
        },
      }

      const { data } = await axios.get(`${url}/user/get_page/${user_id}`, config)

      return {
        data: data,
        user_id: user_id
      }
    } catch (error) {
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message)
      } else {
        return rejectWithValue(error.message)
      }
    }
  }
)

export const showPost = createAsyncThunk(
  'user/show_post',
  async ( postId , { getState, rejectWithValue }) => {
    try {
      // get user data from store
      const { persist } = getState()

      // configure authorization header with user's token
      const config = {
        headers: {
          Authorization: `Bearer ${persist.user.userToken}`,
        },
      }

      const { data } = await axios.get(`${url}/posts/${postId}`, config)

      return data
    } catch (error) {
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message)
      } else {
        return rejectWithValue(error.message)
      }
    }
  }
)

export const showPage = createAsyncThunk(
  'user/show_page',
  async (pageId, { getState, rejectWithValue }) => {
    try {
      // get user data from store
      const { persist } = getState()

      // configure authorization header with user's token
      const config = {
        headers: {
          Authorization: `Bearer ${persist.user.userToken}`,
        },
      }

      const { data } = await axios.get(`${url}/pages/${pageId}`, config)

      return data
    } catch (error) {
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message)
      } else {
        return rejectWithValue(error.message)
      }
    }
  }
)

export const deleteUser= createAsyncThunk(
  'user/delete',
  async (userId, { getState, rejectWithValue }) => {
    try {
      // get user data from store
      const { persist } = getState()
      
      // configure authorization header with user's token
      const config = {
        headers: {
          Authorization: `Bearer ${persist.user.userToken}`,
        },
      }

      const { data } = await axios.delete(`${url}/users/${userId}`, config)
      return data
    } catch (error) {
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message)
      } else {
        return rejectWithValue(error.message)
      }
    }
  }
)

export const deleteAccount = createAsyncThunk(
  'user/delete',
  async (arg, { getState, rejectWithValue }) => {
    try {
      // get user data from store
      const { persist } = getState()
      
      // configure authorization header with user's token
      const config = {
        headers: {
          Authorization: `Bearer ${persist.user.userToken}`,
        },
      }

      const { data } = await axios.delete(`${url}/users/${persist.user.id}`, config)
      return data
    } catch (error) {
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message)
      } else {
        return rejectWithValue(error.message)
      }
    }
  }
)

export const deleteStripeAccount = createAsyncThunk(
  'user/delete',
  async (arg, { getState, rejectWithValue }) => {
    try {
      // get user data from store
      const { persist } = getState()
      
      // configure authorization header with user's token
      const config = {
        headers: {
          Authorization: `Bearer ${persist.user.userToken}`,
        },
      }

      const { data } = await axios.delete(`${url}/accounts/${persist.user.id}`, config)
      return data
    } catch (error) {
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message)
      } else {
        return rejectWithValue(error.message)
      }
    }
  }
)

export const stripeAccount = createAsyncThunk(
  'user/stripe',
  async (categ, { getState, rejectWithValue }) => {
    try {
      // get user data from store
      const { persist } = getState()
      
      // configure authorization header with user's token
      const config = {
        headers: {
          Authorization: `Bearer ${persist.user.userToken}`,
        },
      }

      // console.log('called 2')

      if (categ == 'retrieve_payment') {
        return await axios.get(`${url}/stripe_retrieve_payment`, config)
      // } else if (categ == 'retrieve_account') {
      } else {
        return await axios.get(`${url}/stripe_retrieve_account`, config)
      }


      // return data
    } catch (error) {
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message)
      } else {
        return rejectWithValue(error.message)
      }
    }
  }
)

export const showAccount = createAsyncThunk(
  'user/show',
  async (arg, { getState, rejectWithValue }) => {
    try {
      // get user data from store
      const { persist } = getState()
      
      // configure authorization header with user's token
      const config = {
        headers: {
          Authorization: `Bearer ${persist.user.userToken}`,
        },
      }

      const { data } = await axios.get(`${url}/user/show`, config)

      return data
    } catch (error) {
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message)
      } else {
        return rejectWithValue(error.message)
      }
    }
  }
)

export const forgotPassword = createAsyncThunk(
  'user/forget_password',
  async (
    { 
      email
    }, 
    { rejectWithValue }) => {
    try {
      // configure header's Content-Type as JSON
      const config = {
        headers: {
          'Content-Type': 'application/json',
        },
      }

      const { data } = await axios.post(
        `${url}/forget_password`,
        { email },
        config
      )

      window.location.href = "/verify_code";
      return data.data
    } catch (error) {
      // return custom error message from API if any
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message)
      } else {
        return rejectWithValue(error.message)
      }
    }
  }
)

export const verifyCode = createAsyncThunk(
  'user/verify_code',
  async (
    { 
      code,
    }, 
    { rejectWithValue, }) => {
    try {

      const config = {
        headers: {
          'Content-Type': 'application/json',
        },
      }

      const { data } = await axios.post(
        `${url}/verify_code`,
        {
          code,
        },
        config
      )

      window.location.href = "/reset_password";
      return data

    } catch (error) {
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message)
      } else {
        return rejectWithValue(error.message)
      }
    }
  }
)

export const resetPassword = createAsyncThunk(
  'user/reset_password',
  async (
    { 
      email, 
      password,
    }, 
    { rejectWithValue, }) => {
    try {

      const config = {
        headers: {
          'Content-Type': 'application/json',
        },
      }

      const { data } = await axios.post(
        `${url}/reset_password`,
        {
          email, 
          password,
        },
        config
      )

      window.location.href = "/landing_page";
      return data

    } catch (error) {
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message)
      } else {
        return rejectWithValue(error.message)
      }
    }
  }
)

export const contact = createAsyncThunk(
  'user/contact',
  async (
    { 
      email, 
      name,
      msg,
    }, 
    { rejectWithValue, }) => {
    try {

      const config = {
        headers: {
          'Content-Type': 'application/json',
        },
      }

      const { data } = await axios.post(
        `${url}/guest_contact`,
        {
          email, 
          name,
          msg,
        },
        config
      )
      
      return data

    } catch (error) {
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message)
      } else {
        return rejectWithValue(error.message)
      }
    }
  }
)

export const userSub = createAsyncThunk(
  'user/user_sub',
  async (arg, { getState, rejectWithValue }) => {
    try {
      // get user data from store
      const { persist } = getState()
      
      // configure authorization header with user's token
      const config = {
        headers: {
          Authorization: `Bearer ${persist.user.userToken}`,
        },
      }

      const { data } = await axios.get(`${url}/user_sub`, config)
      return data
    } catch (error) {
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message)
      } else {
        return rejectWithValue(error.message)
      }
    }
  }
)

export const getUsers = createAsyncThunk(
  'user/users',
  async (arg, { getState, rejectWithValue }) => {
    try {
      // get user data from store
      const { persist } = getState()
      
      // configure authorization header with user's token
      const config = {
        headers: {
          Authorization: `Bearer ${persist.user.userToken}`,
        },
      }

      const { data } = await axios.get(`${url}/users`, config)
      return data
    } catch (error) {
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message)
      } else {
        return rejectWithValue(error.message)
      }
    }
  }
)

export const getCountryCoaches = createAsyncThunk(
  'user/country_coaches',
  async (arg, { getState, rejectWithValue }) => {
    try {
      // get user data from store
      const { persist } = getState()
      
      // configure authorization header with user's token
      const config = {
        headers: {
          Authorization: `Bearer ${persist.user.userToken}`,
        },
      }

      const { data } = await axios.get(`${url}/user/country_coaches`, config)
      return data
    } catch (error) {
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message)
      } else {
        return rejectWithValue(error.message)
      }
    }
  }
)



