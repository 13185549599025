import React, { useState, useRef, useEffect } from 'react';
import './banner.css';

import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux'

import { deletePost, editPost, createPost } from "../../routes/PostAction";
import { deletePageUser, createPageUser } from "../../routes/PageUserAction";
import { deleteAlert, editAlert, createAlert } from "../../routes/AlertAction";
import { deleteInfo, editInfo, createInfo } from "../../routes/InfoAction";

import CustomAlert from '../Alert/CustomAlert';

import { timeFormat, timeTable, domain } from "../../dummyData";
import MemberPageModal from '../Modal/MemberModal';
import JoinPageModal from '../Modal/JoinModal';
import CreatePageModal from '../Modal/PageModal';
import EditPageModal from '../Modal/EditPageModal';


const PageBanner = (props) => {
    const dispatch = useDispatch();

    const { loading, error, successMessage, user } = useSelector((state) => state.persist.user)
    const userData = user;

    const navigate = useNavigate();
    const navigateToRoute = (url) => {
        navigate(url);
    };

    const handleJoin = (cat) => {
        if (cat === 'join') {
            let fd = {
                page_id: props.data?.id
            }

            dispatch(createPageUser(fd))
            .then(response =>{
                // if (error) {
                //   customAlertRef.current.handleOpen(error, 'danger');
                
                // } else {
                //     if (successMessage !== '') {
                //         customAlertRef.current.handleOpen(successMessage, 'success');
                //     }
                // }
                triggerRefresh();
            })
            .catch((error) => {
                console.log(error)
            })
        } else {
            let userId = userData.id
            
            dispatch(deletePageUser(userId))
            .then(response =>{
                // if (error) {
                //   customAlertRef.current.handleOpen(error, 'danger');
                
                // } else {
                //     if (successMessage !== '') {
                //         customAlertRef.current.handleOpen(successMessage, 'success');
                //     }
                // }
                triggerRefresh();
            })
            .catch((error) => {
                console.log(error)
            })
        }
    }

    const customAlertRef = useRef()

    const joinRef = useRef()
    const memberRef = useRef()
    const editPageRef = useRef()
    const handleEditPage = () => {
        editPageRef.current.handleOpen();
    };
    const handleOpenJoin = () => {
       joinRef.current.handleOpen();
        
    };
    const handleOpenMember = () => {
        memberRef.current.handleOpen();
        
    };

    const handleMembers = () => {};
    const handleManage = () => {};

    //// forms

    const [showFForm, setShowFForm] = useState(false);
    const [showAForm, setShowAForm] = useState(false);
    const [showPForm, setShowPForm] = useState(false);

    const [postFormData, setPostFormData] = useState({
        title: '',
        about: '',
        long: '',
        lat: '',
        url: '',
        msg: '',
        category: '',
        image: '',
        pageable_type: "App\\Models\\Page",
        pageable_id: props?.data?.id,
        post_id: props?.data?.id,
        status: 0,
    });

    const [alertFormData, setAlertFormData] = useState({
        description: '',
        long: '',
        lat: '',
        status: 0,
    });
    const [infoFormData, setInfoFormData] = useState({
        description: '',
        long: '',
        // coach_id: props.data.id,
        lat: '',
        status: 0,
    });

    const handlePostFormChange = (e) => {
        if (e.target.name === 'status') {
            setPostFormData({ ...postFormData, [e.target.name]: e.target.checked? 0: 1 });
        } else if (e.target.name === 'is_private') {
            setPostFormData({ ...postFormData, [e.target.name]: e.target.checked? 1: 0 });
        } else if (e.target.name === 'is_online') {
            setPostFormData({ ...postFormData, [e.target.name]: e.target.checked? 1: 0 });
        } else if(e.target.name === 'image') {

            const files = e.target.files;
            if (files && files.length > 0) {
                const file = files[0];
                setPostFormData({ ...postFormData, [e.target.name]: file });
            } else {
                setPostFormData({ ...postFormData, [e.target.name]: '' });
                console.error("No file selected");
            }
        } else {
            setPostFormData({ ...postFormData, [e.target.name]: e.target.value });
        }
    }

    const handleInfoFormChange = (e) => {
        if (e.target.name === 'status') {
            setInfoFormData({ ...infoFormData, [e.target.name]: e.target.checked? 0: 1 });
            } else {
            setInfoFormData({ ...infoFormData, [e.target.name]: e.target.value });
        }
    }

    const handleAlertFormChange = (e) => {
        if (e.target.name === 'status') {
            setAlertFormData({ ...alertFormData, [e.target.name]: e.target.checked? 0: 1 });
            } else {
            setAlertFormData({ ...alertFormData, [e.target.name]: e.target.value });
        }
    }

    const showForm = (type) => {
        switch (type) {
            case "post":
                setPostFormData({
                    title: '',
                    about: '',
                    long: '',
                    lat: '',
                    url: '',
                    msg: '',
                    category: '',
                    image: '',
                    pageable_type: "App\\Models\\Page",
                    pageable_id: props.data.id,
                    post_id: props.data.id,
                    status: 0,
                })
                return setShowPForm(!showPForm)
            case "info":
                // setInfoFormData({
                //     description: '',
                //     state: '',
                //     city: '',
                //     long: '',
                //     lat: '',
                //     coach_id: props.data.id,
                //     status: 0,
                // })
                return setShowFForm(!showFForm)
            case "alert":
                // setAlertFormData({
                //     description: '',
                //     state: '',
                //     city: '',
                //     long: '',
                //     lat: '',
                //     status: 0,
                // })
                return setShowAForm(!showAForm)
            default:
                break
        }
    }

    const hideForm = (type) => {
        switch (type) {
            case "post":
                return setShowPForm(false)
            case "info":
                return setShowFForm(false)
            case "alert":
                return setShowAForm(false)
            default:
                break
        }
    }

    const triggerRefresh = () => {
        props.fetchPage();
    }

    const submitData = (type) => {
        switch (type) {
            case "post":
                // console.log('newd', postFormData)
                // if(props.data?.account === 5) {
                    if(postFormData.title === '') {
                        return customAlertRef.current.handleOpen("Form filled incorrectly", 'danger');
                    } else {
                        return postFormData.id > 0? 
                        dispatch(editPost(postFormData))
                        : dispatch(createPost(postFormData))
                        .then(response => {
                            if (error) {
                                // console.log('er', error)
                                customAlertRef.current.handleOpen(error, 'danger');
                            
                            } else {
                                if (successMessage !== '') {
                                    customAlertRef.current.handleOpen(successMessage, 'success');
                                }
                            }
                            triggerRefresh();
                        })
                        .catch((error) => {
                            console.log(error)
                            customAlertRef.current.handleOpen(error, 'danger');
                        })
                    }
                    
                // } else {
                //     // account not linked
                //     customAlertRef.current.handleOpen("Sorry it seems you haven't activated payment", 'danger');
                // }
            case "info":
                if(infoFormData.description === '') return
                return infoFormData.id > 0? 
                dispatch(editInfo(infoFormData))
                : dispatch(createInfo(infoFormData))
                .then(response =>{
                    if (error) {
                        // console.log('er', error)
                        customAlertRef.current.handleOpen(error, 'danger');
                    
                    } else {
                        if (successMessage !== '') {
                            customAlertRef.current.handleOpen(successMessage, 'success');
                        }
                    }
                    triggerRefresh();
                })
                .catch((error) => {
                    console.log(error)
                    customAlertRef.current.handleOpen(error, 'danger');
                })
            case "alert":
                if(alertFormData.description === '') return
                return alertFormData.id > 0? 
                dispatch(editAlert(alertFormData))
                : dispatch(createAlert(alertFormData))
                .then(response =>{
                    if (error) {
                        // console.log('er', error)
                        customAlertRef.current.handleOpen(error, 'danger');
                    
                    } else {
                        if (successMessage !== '') {
                            customAlertRef.current.handleOpen(successMessage, 'success');
                        }
                    }
                    triggerRefresh();
                })
                .catch((error) => {
                    console.log(error)
                    customAlertRef.current.handleOpen(error, 'danger');
                })
            default:
                break
        }
        
    }

    //// end forms

    return (
        <>
            <CustomAlert ref={customAlertRef}/>
            <MemberPageModal 
                title={' View Members'}
                ref={memberRef}
                data={props.data?.page_users}
                // handleSubmit={''}
                
            />
            <JoinPageModal
                title={' Join Group'}
                ref={joinRef}
                // handleSubmit={''}
                
            />
            <EditPageModal
                title={' Edit Group'}
                ref={editPageRef}
                data={props.data}
                // handleSubmit={''}
                
            />

            <div className="b-screen">
                <div className="d-flex justify-content-between mx-3 p-3 my-2" style={{
                    backgroundColor:'#E1F2FE', 
                    // border:"solid 1px #ec2f2f", 
                    borderRadius:'10px'
                    }}>
                    <div className="row">
                        <div className="col-3">
                            <img alt='ddk' src= {props.data?.image? domain+props.data?.image:"./assets/images/1.jpg"} className="rounded-circle" width="80" height="80">
                            </img>
                        </div>
                        <div className="col-9">
                            <h6 style={{marginBottom:'0', marginTop:'0', marginLeft:'20px', color:'#ec2f2f', fontSize:20,}}>{props.data?.title}</h6>
                            <h6 style={{marginBottom:'0', marginTop:'0', marginLeft:'20px', color:'#ec2f2f'}}>
                                <i style={{marginRight:'5px'}} className="fa-solid fa-magnifying-glass"></i>
                                {props.data?.user?.name? props.data?.user?.name: ''}
                            </h6>
                            <h6 style={{marginBottom:'0', marginTop:'0', marginLeft:'20px', color:'#F9CC2E'}}>Created: {timeFormat(props.data?.created_at)}</h6>
                            {/* <span onClick={handleDeleteAccount} style={{marginLeft:'20px',  border:"solid 1px #ec2f2f", color:'#ec2f2f'}} className="badge px-3 rounded-pill">
                                <i style={{color:'#ec2f2f', marginRight:'5px'}} className="fa-solid fa-bolt"></i>
                                Delete Acct
                            </span> */}
                        </div>
                    </div>
                    <div>
                        <h6 style={{marginBottom:'0', marginTop:'0', color:'#ec2f2f', textAlign:'center'}}>Posts: ({props.data?.posts?.length})</h6>

                        {/* <h6 style={{marginBottom:'0', marginTop:'0', color:'#ec2f2f'}}>Credit: $ 500</h6> */}

                        <div className="d-flex flex-row my-1 justify-content-center">
                        
                            <div className='mx-1'>
                                <span onClick={() => showForm('post')} style={{ border:"solid 1px #ec2f2f", color:'#ec2f2f', marginRight:'5px'}} className="badge px-1 py-1 rounded-pill">
                                    <i style={{color:'#ec2f2f', fontSize:16}} className="fa-solid fa-add"></i>
                                </span>
                                <h6 style={{marginBottom:'0', marginTop:'0', marginLeft:'0', color:'#ec2f2f', fontSize: '0.8em', textalign:'center'}}>Post</h6>
                            </div>
                            {/* <div className='mx-1'>
                                <span onClick={() => showForm('location')} style={{ border:"solid 1px #ec2f2f", color:'#ec2f2f', marginRight:'5px'}} className="badge px-1 py-1 rounded-pill">
                                    <i style={{color:'#ec2f2f', fontSize:16}} className="fa-solid fa-location"></i>
                                </span>
                                <h6 style={{marginBottom:'0', marginTop:'0', marginLeft:'0', color:'#ec2f2f', fontSize: '0.8em', textalign:'center'}}>Venue</h6>
                            </div> */}
                            <div className='mx-1'>
                                <span onClick={() => showForm('alert')} style={{ border:"solid 1px #ec2f2f", color:'#ec2f2f', marginRight:'5px'}} className="badge px-1 py-1 rounded-pill">
                                    <i style={{color:'#ec2f2f', fontSize:16}} className="fa-solid fa-bell"></i>
                                </span>
                                <h6 style={{marginBottom:'0', marginTop:'0', marginLeft:'0', color:'#ec2f2f', fontSize: '0.8em', textalign:'center'}}>Alert</h6>
                            </div>

                            {/* <div className='mx-1'>
                                <span onClick={() => showForm('plan')} style={{ border:"solid 1px #ec2f2f", color:'#ec2f2f', marginRight:'5px'}} className="badge px-1 py-1 rounded-pill">
                                    <i style={{color:'#ec2f2f', fontSize:16}} className="fa-solid fa-calendar"></i>
                                </span>
                                <h6 style={{marginBottom:'0', marginTop:'0', marginLeft:'0', color:'#ec2f2f', fontSize: '0.8em', textalign:'center'}}>Posts</h6>
                            </div> */}
                            <div className='mx-1'>
                                <span onClick={() => {navigateToRoute(`/chat?id=${props.data?.chat?.uuid}&&code=${props.data?.user?.uuid}&&type=page`)}} style={{ border:"solid 1px #ec2f2f", color:'#ec2f2f', marginRight:'5px'}} className="badge px-1 py-1 rounded-pill">
                                    <i style={{color:'#ec2f2f', fontSize:16}} className="fa-solid fa-message"></i>
                                </span>
                                <h6 style={{marginBottom:'0', marginTop:'0', marginLeft:'0', color:'#ec2f2f', fontSize: '0.8em', textalign:'center'}}>Chat</h6>
                            </div>


                        </div>
                        
                    </div>
                    <div className="d-flex flex-column">
                        <div className="d-flex justify-content-center">
                            <span style={{backgroundColor:'#ec2f2f', border:"solid 1px #ec2f2f", maxWidth:'150px'}} className="badge px-3 py-2 my-1 rounded-pill d-flex justify-content-center" onClick={handleOpenMember}>Members</span>
                        </div>

                        {
                            props.data?.member?
                                <span style={{border:"solid 1px #ec2f2f", color:'#ec2f2f'}} className="badge px-3 py-2 my-1 rounded-pill" onClick={()=>{handleJoin('leave')}}>
                                    Leave
                                </span>
                            :
                                <span style={{border:"solid 1px #ec2f2f", color:'#ec2f2f'}} className="badge px-3 py-2 my-1 rounded-pill" onClick={()=>{handleJoin('join')}}>
                                    Join
                                </span>

                        }
                    </div>
                    
                </div>
            </div>

            <div className="mx-3 p-3 my-2 m-screen" style={{
                backgroundColor:'#E1F2FE', 
                // border:"solid 1px #ec2f2f", 
                borderRadius:'10px'
                }}>
                <div className="row">
                    <div className="d-flex justify-content-center">
                        <img alt='ddk' src= {props.data?.image? domain+props.data?.image:"./assets/images/1.jpg"} className="rounded-circle" width="80" height="80">
                        </img>
                    </div>
                </div>
                <div className="d-flex flex-column">
                    {/* <div className="d-flex justify-content-center"> */}
                        <h6 className="d-flex justify-content-center" style={{marginBottom:'0', marginTop:'0', marginLeft:'20px', color:'#ec2f2f', fontSize:20}}>{props.data?.title}</h6>
                        
                        <h6 className="d-flex justify-content-center" style={{marginBottom:'0', marginTop:'0', marginLeft:'20px', color:'#ec2f2f'}}>
                            <i style={{marginRight:'5px'}} className="fa-solid fa-magnifying-glass"></i>
                            {props.data?.user?.name? props.data?.user?.name: ''}
                        </h6>
                        <h6 className="d-flex justify-content-center" style={{marginBottom:'0', marginTop:'0', marginLeft:'20px', color:'#F9CC2E'}}>Created: {timeFormat(props.data?.createdAt)}</h6>
                        
                    {/* </div> */}
                </div>
                {/* <div className="d-flex justify-content-center">
                    <span onClick={handleDeleteAccount} style={{ border:"solid 1px #ec2f2f", maxWidth:'150px', color:'#ec2f2f'}} className="badge px-2 py-2 my-1 rounded-pill">
                        <i style={{color:'#ec2f2f', marginRight:'5px'}} className="fa-solid fa-bolt"></i>
                        Delete Acct
                    </span>
                </div> */}
                <div className="row">
                    <h6 className="d-flex justify-content-center" style={{ marginLeft:'', marginBottom:'0', marginTop:'0', color:'#ec2f2f', textAlign:'center'}}>Posts: ({props.data?.posts?.length}) </h6>

                    {/* <h6 className="d-flex justify-content-center" style={{ marginLeft:'', marginBottom:'0', marginTop:'0', color:'#ec2f2f'}}>Credit: $ 500</h6> */}

                    <div className="d-flex flex-row my-1 justify-content-center">
                        <div className='mx-1'>
                            <span onClick={() => showForm('post')} style={{ border:"solid 1px #ec2f2f", color:'#ec2f2f', marginRight:'5px'}} className="badge px-1 py-1 rounded-pill">
                                <i style={{color:'#ec2f2f', fontSize:16}} className="fa-solid fa-add"></i>
                            </span>
                            <h6 style={{marginBottom:'0', marginTop:'0', marginLeft:'0', color:'#ec2f2f', fontSize: '0.8em', textalign:'center'}}>Post</h6>
                        </div>
                        {/* <div className='mx-1'>
                            <span onClick={() => showForm('location')} style={{ border:"solid 1px #ec2f2f", color:'#ec2f2f', marginRight:'5px'}} className="badge px-1 py-1 rounded-pill">
                                <i style={{color:'#ec2f2f', fontSize:16}} className="fa-solid fa-location"></i>
                            </span>
                            <h6 style={{marginBottom:'0', marginTop:'0', marginLeft:'0', color:'#ec2f2f', fontSize: '0.8em', textalign:'center'}}>Venue</h6>
                        </div> */}
                        <div className='mx-1'>
                            <span onClick={() => showForm('alert')} style={{ border:"solid 1px #ec2f2f", color:'#ec2f2f', marginRight:'5px'}} className="badge px-1 py-1 rounded-pill">
                                <i style={{color:'#ec2f2f', fontSize:16}} className="fa-solid fa-bell"></i>
                            </span>
                            <h6 style={{marginBottom:'0', marginTop:'0', marginLeft:'0', color:'#ec2f2f', fontSize: '0.8em', textalign:'center'}}>Alert</h6>
                        </div>
                        {/* <div className='mx-1'>
                            <span onClick={() => showForm('plan')} style={{ border:"solid 1px #ec2f2f", color:'#ec2f2f', marginRight:'5px'}} className="badge px-1 py-1 rounded-pill">
                                <i style={{color:'#ec2f2f', fontSize:16}} className="fa-solid fa-calendar"></i>
                            </span>
                            <h6 style={{marginBottom:'0', marginTop:'0', marginLeft:'0', color:'#ec2f2f', fontSize: '0.8em', textalign:'center'}}>Sub</h6>
                        </div> */}
                        <div className='mx-1'>
                            <span onClick={() => {navigateToRoute(`/chat?id=${props.data?.chat?.uuid}&&code=${props.data?.user?.uuid}&&type=page`)}} style={{ border:"solid 1px #ec2f2f", color:'#ec2f2f', marginRight:'5px'}} className="badge px-1 py-1 rounded-pill">
                                <i style={{color:'#ec2f2f', fontSize:16}} className="fa-solid fa-message"></i>
                            </span>
                            <h6 style={{marginBottom:'0', marginTop:'0', marginLeft:'0', color:'#ec2f2f', fontSize: '0.8em', textalign:'center'}}>Chat</h6>
                        </div>
                    </div>
                    
                </div>

                <div className="d-flex flex-column">
                    <div className="d-flex justify-content-center">
                        <span style={{backgroundColor:'#ec2f2f', border:"solid 1px #ec2f2f", maxWidth:'150px'}} className="badge px-3 py-2 my-1 rounded-pill d-flex justify-content-center" onClick={handleOpenMember}>Members</span>
                    </div>
                    <div className="d-flex justify-content-center">
                        {
                            props.data?.member?
                                <span style={{border:"solid 1px #ec2f2f", color:'#ec2f2f', maxWidth:'150px'}} className="badge px-3 py-2 my-1 rounded-pill d-flex justify-content-center" onClick={()=>{handleJoin('leave')}}>
                                    Leave
                                </span>
                            :
                                <span style={{border:"solid 1px #ec2f2f", color:'#ec2f2f', maxWidth:'150px'}} className="badge px-3 py-2 my-1 rounded-pill d-flex justify-content-center" onClick={()=>{handleJoin('join')}}>
                                    Join
                                </span>

                        }
                        
                    </div>
                    
                </div>
                
            </div>

            {showPForm === true? 
                <div className="mx-4 p-3" style={{
                        backgroundColor:'#E1F2FE', 
                        // border:"solid 1px #ec2f2f", 
                        borderRadius:'10px'
                        }}>
                    <h3 className="d-flex justify-content-center">Post Form</h3>
                    <div id='post-form'>

                        
                        <div className='row'>
                            <div className="mb-3 col-sm-12 col-md-3">
                                <label className="form-label">Title</label>
                                <input required type="text" value={postFormData.title} onChange={handlePostFormChange} name='title' className="form-control form-control-sm" id="exampleFormControlInput1" placeholder="name"/>
                            </div>
                            <div className="mb-3 col-sm-12 col-md-3">
                                <label className="form-label">Image</label>
                                <input type="file" onChange={handlePostFormChange} name='image' className="form-control form-control-sm" id="exampleFormControlInput1" placeholder="image"/>
                            </div>
                            {/* <div className="mb-3 col-sm-12 col-md-3">
                                <label className="form-label">Category</label>
                                <input required type="text" value={postFormData.title} onChange={handlePostFormChange} name='title' className="form-control form-control-sm" id="exampleFormControlInput1" placeholder="name"/>
                            </div> */}
                            <div className="mb-3 col-sm-12 col-md-3">
                                <label className="form-label">Msg</label>
                                <input required type="text" value={postFormData.msg} onChange={handlePostFormChange} name='msg' className="form-control form-control-sm" id="exampleFormControlInput1" placeholder="msg"/>
                            </div>
                            <div className="mb-3 col-sm-12 col-md-3">
                                <label className="form-label">Link</label>
                                <input required type="text" value={postFormData.url} onChange={handlePostFormChange} name='url' className="form-control form-control-sm" id="exampleFormControlInput1" placeholder="url"/>
                            </div>
                            <div className="mb-3 col-sm-12 col-md-12">
                                <label className="form-label">Description</label>
                                <input type="text" value={postFormData.about? postFormData.about: ''} name='about' onChange={handlePostFormChange} className="form-control form-control-sm" id="exampleFormControlInput1" placeholder="about"/>
                            </div>
                        </div>

                        <div className="d-flex justify-content-center mb-3">
                            <div className="d-flex mx-auto">
                                <div className="form-check form-switch col-3 mx-auto">
                                    <input name='status' className="form-check-input" type="checkbox" role="switch" id="flexSwitchCheckChecked" onChange={handlePostFormChange} checked={postFormData.status === 0? true: false}/>
                                    <label className="form-check-label">Status</label>
                                </div>
                            </div>
                        </div>

                        <div className="d-flex justify-content-center">
                            <div className="d-flex mx-auto">
                                <div className="mb-3 ml-4">
                                    <button onClick={() => hideForm('post')} className="btn btn-sm btn-secondary">Close</button>
                                </div>
                                <div className="mb-3 col-4">
                                    <button onClick={() => {submitData('post')}} className="btn btn-sm btn-danger">Submit</button>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            : <></>}

            {showFForm === true? 
                <div className="mx-4 p-3" style={{
                        backgroundColor:'#E1F2FE', 
                        // border:"solid 1px #ec2f2f", 
                        borderRadius:'10px'
                        }}>
                    <h3 className="d-flex justify-content-center">Info</h3>
                    <div id='training-location-form'>

                        <div className="card shadow mb-4" style={{height: "100%"}}>
                            <div className="card-header py-3">
                                <h6 className="m-0 font-weight-bold text-primary">Info Table</h6>
                            </div>
                            <div className="card-body">
                                <div className="table-responsive" style={{height: "300px"}}>
                                    <table className="table table-bordered table-striped table-hover" width="100%" cellSpacing="0">
                                        <thead>
                                            <tr>
                                                <th>Description</th>
                                                <th>Status</th>
                                                <th>State</th>
                                                <th>City</th>
                                                <th>Created date</th>
                                                <th>Action</th>
                                            </tr>
                                        </thead>
                                        {/* <tbody style={{}}>
                                            {props.data?.trainingLocations?.map((row) => (
                                                <tr key={row.id}><td>{row.description}</td>
                                                    <td>
                                                        {row.status === 0? 
                                                            <span className="badge text-bg-success">Active</span>
                                                        : 
                                                            <span className="badge text-bg-danger">In Active</span>
                                                        }
                                                    </td>
                                                    <td>{row.state}</td>
                                                    <td>{row.city}</td>
                                                    <td>{timeTable(row.created_at)}</td>
                                                    <td>
                                                        <i onClick={() => {setRow(row, 'location')}}  className="fas fa-edit text-success mr-2"></i>
                                                        <i onClick={() => {deleteRow(row, 'location')}} className="fas fa-trash text-danger ml-2"></i>
                                                    </td>
                                                </tr>
                                            ))}
                                            

                                        </tbody> */}
                                    </table>
                                </div>
                            </div>
                        </div>
                        

                    </div>
                </div>
            : <></>}

            {showAForm === true? 
                <div className="mx-4 p-3" style={{
                        backgroundColor:'#E1F2FE', 
                        // border:"solid 1px #ec2f2f", 
                        borderRadius:'10px'
                        }}>
                    <h3 className="d-flex justify-content-center">Info</h3>
                    {props.data?.share_logs?.length > 0 ? 
                        props.data?.share_logs?.map((row) => (
                        <div key={row.id} style={{backgroundColor:'#F9CC2E'}} className="d-flex flex-row justify-content-between px-1 py-1 my-1">
                            
                            <div className="d-flex flex-column px-1 py-1">
                            <h6 style={{color:'#ec2f2f', fontSize:25, fontStyle:'italic'}} className="my-1">{row.about}</h6>
                            </div>
                        </div>
                        ))
                            
                        : 

                        <h5 style={{color:'#ec2f2f', fontSize: 12, textAlign:'center'}}>No Info Yet...</h5>
                    }
                    
                </div>
            : <></>}
            
        </>
    );

}

export default PageBanner