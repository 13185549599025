import React, { useEffect, useState, useRef } from 'react';

import Footer from "../components/Footer/Footer"
import Header from "../components/Header/Header";

import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, Link } from 'react-router-dom'
import {createPageUser} from '../routes/PageUserAction';
import CustomAlert from '../components/Alert/CustomAlert';

// import "./home.css"
import "./edit.css"
import { domainUrl, domain } from '../dummyData';
let url =  domain;

const SharePage = () => {
  const { loading, user, error, success, successMessage } = useSelector((state) => state.persist.user)
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const errorDialogRef = useRef()

  const [errorMsg, setErrorMsg] = useState('');
  const customAlertRef = useRef()

  const [edit, setEdit] = useState(false);
  const [formData, setFormData] = useState({
    account_no: '',
    page_id: 0,
    account_name: user.name,
    account: '',
    terms: 0,
    total: '0.00',
    tax: '0.00',
    status: 0,

  });

  const handleResponse = (value) => {
    if (value) {
      setFormData({...formData, lat:value.lat, long:value.lng });
    } else {
      setFormData({...formData, lat:'', long:'' });
    }
    
  }

  const {
    account_no,
    page_id,
    account_name,
    account,
    terms,
    total,
    tax,
    status,
  } = formData;

  const clearData = () => {
    setFormData({ 
      ...formData, 
      account_no: '',
      page_id: 0,
      account_name: user.name,
      account: '',
      terms: 0,
      total: '0.00',
      tax: '0.00',
      status: 0,

    });
  };

  const handleChange = (e) => {
    if (e.target.name === 'terms') {
      setFormData({ ...formData, [e.target.name]: e.target.checked ? 1 : 0 });
    } else {
      setFormData({ ...formData, [e.target.name]: e.target.value });
    }
    
  };

  const handleSubmit = (event) => {
    event.preventDefault();
      if (account_name !== '' && account_no != '') {
        dispatch(createPageUser(formData))
        .then(response =>{
          if (error) {
            customAlertRef.current.handleOpen(error, 'danger');
          }else {
            if (successMessage) {
              customAlertRef.current.handleOpen(successMessage, 'success');
            }
          }
        });
    } else {
      customAlertRef.current.handleOpen('Form filled incorrectly!', 'danger');
    }

  };

  return (
    <>
      {/* reg */}
      <Header page={'ghana_bas'}/>
      <div className="divider"></div>
      {/* <ErrorDialog ref={errorDialogRef} msg={errorMsg}/> */}
      <CustomAlert ref={customAlertRef} status={'danger'} msg={errorMsg}/>
      
      {loading ? (
        <div className="loader-container">
            <div className="spinner"></div>
            {/* <h6 style={{color: 'white', textAlign:'center'}}>loading please wait...</h6> */}
        </div>
      ) :
      <div style={{}} className=''>
        <div className="b-screen pb-4 overflow-y-auto" style={{backgroundColor:'white', height:'100%'}}>

          {/* <PageHeaderTwo screen={'big'}/> */}
          <div className="d-flex justify-content-center mb-5">
            <div className="rounded-4 d-flex flex-column justify-content-center my-auto pb-2" style=
            {{width:'40vw', height:'80%', backgroundColor:'#FFFFFF', border:"solid 2px #F8BC16"}}>

              <form onSubmit={handleSubmit} className="d-flex flex-column justify-content-center my-auto mx-3">
                <div className="d-flex flex-row justify-content-center col-12 my-auto mx-auto">
                  
                </div>
                

                <div className="mt-3 mb-2">
                  <select value={formData.page_id? formData.page_id: ''} name='page_id' onChange={handleChange} className="form-select form-select-sm" aria-label=".form-select-sm example">
                      <option value="">Select Investment Area</option>
                      <option value="4">Agro Secure</option>
                      <option value="5">Mining</option>
                  </select>
                </div>

                <input value={formData.account_name} onChange={handleChange} className="form-control form-control-sm mb-2" required type="text" placeholder="Account Name" name='account_name'/> 

                <div className="mb-2">
                  <select value={formData.account? formData.account: ''} name='account' onChange={handleChange} className="form-select form-select-sm" aria-label=".form-select-sm example">
                      <option value="">Select Payment</option>
                      <option value="momo">MTN (MOMO)</option>
                      <option value="bank">Bank</option>
                  </select>
                </div>

                <input value={formData.account_no? formData.account_no: ''} onChange={handleChange} className="form-control form-control-sm mt-1" type="text" placeholder="Account No" name='account_no' required/>

                <div className='d-flex justify-content-center my-2 mx-auto flex-row'>
                  
                  <div className="form-check form-check-inline col-12">
                    <input checked={formData.terms === 1} onChange={handleChange} className="form-check-input" type="checkbox" id="inlineCheckbox2" value={formData.terms === 1 ? 1 : 0} name='terms' style={{ borderColor: '#ec2f2f', boxShadow: '0 0 0 1px #ec2f2f', backgroundColor: '#ec2f2f' }}/>
                    <label className="form-check-label" for="inlineCheckbox2">Agreed to conditions</label>
                  </div>
                </div>

                <button 
                  style={{color:'#FFFFFF', backgroundColor:'#F8BC16', width:'120px', border:"solid 1px #16307D"}} type="submit" className="btn btn-sm rounded-4 mx-auto">
                    Save
                </button>
              </form>
            
            </div>
          </div>

        </div>

      </div>
      }
      <Footer/>
    </>
  )
}

export default SharePage
