// import * as React from 'react';

import React, { useState, useRef, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux'
import {contact} from '../../routes/UserAction';
import CustomAlert from '../../components/Alert/CustomAlert';
import { domainUrl } from '../../dummyData';

import Header from '../../components/Header/Header';
import Footer from '../../components/Footer/Footer';
// import './contact.css'

export default function Checkout() {
  const { loading, user, error, success, successMessage } = useSelector((state) => state.persist.user)
  const dispatch = useDispatch();
  const customAlertRef = useRef()

  const [errorMsg, setErrorMsg] = useState('');
  const [submitting, setSubmitting] = useState(false);

  const [formData, setFormData] = useState({
      name: "",
      msg: "",
      email: "",
  });

  const [flagData, setFlagData] = useState([
    // {id: 1, name: 'app_email', value: 'info@nextlevel-football.com'},
  ]);

  useEffect(() => {

    let url =  `${domainUrl}/guest_flags`

    fetch(url)
    .then(response => {
      return response.json()
    })
    .then(data => {
      // console.log('tee', data.data)
      setFlagData(data.data)
    })
  }, [])

  const {
      name, 
      email,
      msg,
  } = formData;

  
  const handleChange = (e) => {
      setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = (event) => {
      event.preventDefault();
      setSubmitting(true);
      
      if (email !== '' && name !== '' && msg!= '' ) {
      
          dispatch(contact(formData))
          .then(response =>{
              setSubmitting(true);


              if (error) {
                  // console.log('er', error)
                  setErrorMsg(error);
                  customAlertRef.current.handleOpen(error, 'danger');
                  setSubmitting(false);
              
              } else {
                if (successMessage !== '') {
                  customAlertRef.current.handleOpen(successMessage, 'success');
                  setFormData({
                    name: "",
                    msg: "",
                    email: "",
                  });
                  setSubmitting(false);
                }
              }
          }).catch((error) => {
              setSubmitting(false);
              customAlertRef.current.handleOpen(error, 'danger');
          });
      } else {
          customAlertRef.current.handleOpen('Form filled incorrectly!', 'danger');
          setSubmitting(false);
      }
  };

  return (
    loading ? (
      <div className="loader-container">
          <div className="spinner"></div>
          {/* <h6 style={{color: 'white', textAlign:'center'}}>loading please wait...</h6> */}
      </div>
    ) :
    <>
      <Header page={'contact'}/>
      <div className="divider"></div>

        <div className='mx-3'>
          <div className="card">
              <div className="d-flex flex-column mx-auto">
                  <h2 style={{color:"#ec2f2f"}}>Get in touch!</h2>
                  <p style={{color:"#ec2f2f"}}>Contact our team directly</p>
              </div>
              <div className="d-flex flex-row justify-content-between mx-auto stats">
                  {/* <div className="mr-3">
                      <span style={{color:"#ec2f2f"}}><i className="fa fa-map-marker"></i></span>
                      <span style={{color:"#ec2f2f"}}>102 Street 2714 Don</span>
                  </div> */}
                  <div className="mr-3">
                      <span style={{color:"#ec2f2f"}}><i className="fa fa-phone"></i></span>
                      <span style={{color:"#ec2f2f"}}>{flagData?.filter(el => (el.name == 'app-phone'))?.length? flagData?.filter(el => (el.name === 'app-phone'))[0]?.value : ""}</span>
                  </div>
                  <div className="">
                      <span style={{color:"#ec2f2f"}}><i className="fa fa-envelope"></i></span>
                      <span style={{color:"#ec2f2f"}}>{flagData?.filter(el => (el.name == 'app-email'))?.length? flagData?.filter(el => (el.name === 'app-email'))[0]?.value : "info@nana-frimpomaa.com"}</span>
                  </div>
              </div>
              <div className="card1 col-6 d-flex mx-auto">
                <div className="col-lg-12 col-md-4 mb-4 mb-lg-0">
                  <h5 className="text-uppercase text-white mb-4" style={{color:"#ec2f2f"}}>Sign up to our newsletter</h5>

                  <CustomAlert ref={customAlertRef} status={'danger'} msg={errorMsg}/>
                  <form onSubmit={handleSubmit}>
                    <div className="form-outline form-white mb-1">
                        <input onChange={handleChange} value={name} style={{color:"#ec2f2f"}} type="text" id="form5Example2" className="form-control" placeholder="Name" name='name' required/>
                    </div>
                    <div className="form-outline form-white mb-1">
                        <input onChange={handleChange} value={email} style={{color:"#ec2f2f"}} type="email" name='email' id="form5Example2" className="form-control" placeholder="Email" required/>
                    </div>
                      <div className="form-outline">
                          
                          <textarea onChange={handleChange} value={msg} style={{color:"#ec2f2f"}} placeholder="Message" name="msg" id="msg" className="form-control mb-1" rows="3" required="required"></textarea>
                      </div>
                      <button disabled={submitting} className="btn btn-sm btn-danger d-flex mx-auto" type="submit">Submit</button>
                  </form>
                </div>
              </div>
              
            </div>

        </div>

      <Footer/>
    </>
  );
}
