import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const SampleNextArrow = (props) => {
  const { className, style, onClick } = props;
  return (
    <div className={className} style={{ background: "#ec2f2f" }} onClick={onClick}>
      right
    </div>
  );
}

const SamplePrevArrow = (props) => {
  const { className, style, onClick } = props;
  return (
    <div className={className} style={{ background: "#ec2f2f" }} onClick={onClick}>
      left
    </div>
  );
}

const SlickHorizontalScroll = (props) => {

    var settings = {
        // autoplay: true,
        // autoplaySpeed: 2000,
        // pauseOnHover: true,
        swipeToSlide: true,

        className: "center",
        centerMode: true,
        centerPadding: "60px",
        dots: props.dots? props.dots: false,
        infinite: true,
        speed: props.speed? props.speed: 500,
        slidesToShow: props.slidesToShow? props.slidesToShow: 4,
        //slidesToScroll: 5,
        initialSlide: 0,
        nextArrow: <SampleNextArrow />,
        prevArrow: <SamplePrevArrow />,
        responsive: [
          {
            breakpoint: 1024,
            settings: {
              slidesToShow: 3,
              centerPadding: "60px",
              //slidesToScroll: 3,
              dots: props.dots? props.dots: false
            }
          },
          {
            breakpoint: 900,
            settings: {
              slidesToShow: 2,
              centerPadding: "60px",
              //slidesToScroll: 2,
              dots: props.dots? props.dots: false
            }
          },
          {
            breakpoint: 700,
            settings: {
              slidesToShow: 2,
              centerPadding: "60px",
              //slidesToScroll: 2,
              dots: props.dots? props.dots: false
            }
          },
          {
            breakpoint: 600,
            settings: {
              slidesToShow: 1,
              centerPadding: "100px",
              //slidesToScroll: 2,
              dots: props.dots? props.dots: false
            }
          },
          {
            breakpoint: 500,
            settings: {
              slidesToShow: 1,
              centerPadding: "20px",
              //slidesToScroll: 2,
              dots: props.dots? props.dots: false
            }
          },
          {
            breakpoint: 480,
            settings: {
              slidesToShow: 1,
              centerPadding: "15px",
              //slidesToScroll: 1,
              dots: props.dots? props.dots: false
            },
            
          },
          {
            breakpoint: 300,
            settings: {
              slidesToShow: 1,
              centerPadding: "0.1px",
              //slidesToScroll: 1,
              dots: props.dots? props.dots: false
            },
            
          },
          {
            breakpoint: 200,
            settings: {
              slidesToShow: 1,
              centerPadding: "0.2px",
              //slidesToScroll: 1,
              dots: props.dots? props.dots: false
            },
            
          }
        ]
    }
  
    return (
        props.data?.length > 0 ?<Slider {...settings}>
            {props.data.map((row) => (
                <props.card
                key={row.id}
                data={row}
                // onClick={handleClick(id)}
                // selected={isItemSelected(id)}
                />
            ))}
        </Slider>
        : <h1>No Data...</h1>
    );
}

export default SlickHorizontalScroll