import React, { forwardRef, useImperativeHandle, useState, useEffect } from 'react';

import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import './Custom.css'

import { useDispatch, useSelector } from 'react-redux'
import {getFlags} from '../../routes/FlagAction';

import { domain, domainUrl } from '../../dummyData';
let url =  domain;

const TermsModal = forwardRef((props, ref) => {
  const [show, setShow] = useState(false);

  const handleOpen = () => {
    setShow(true)
  };

  const handleClose = () => {
    setShow(false)
  };


  useImperativeHandle(
    ref,
    () => ({
      handleOpen() {handleOpen()},
      handleClose() {handleClose()},
    }),
  )

  const handleSubmit = () => {
    props.handleSubmit()
  };

  const dispatch = useDispatch();

  const [selected, setSelected] = useState(1);
  const [indexNo, setIndexNo] = useState(0);

  const [pageFlagData, setPageFlagData] = useState([]);
  const [postFlagData, setPostFlagData] = useState([]);

  const handleSelected = (e) => {
    // setSelected(e.target.value)
    setIndexNo(1);
    setSelected(parseInt(e.target.value, 10));
    // console.log(selected)
  };

  const fetchData = () => {

    let url =  `${domainUrl}/guest_flags`

    fetch(url)
    .then(response => {
        return response.json()
    })
    .then(data => {
        // console.log('tee', data.data)
        // setFlagData(data.data)

        const editableData = data.data;

        setPageFlagData(editableData?.filter(el => (el.name === 'app-terms-page')))
        setPostFlagData(editableData?.filter(el => (el.name === 'app-terms-post')))
    })
    .catch((error) => {
      console.log(error)
    })

    dispatch(getFlags())
    .then(response =>{
      let resData = response.payload.data;
      const editableData = resData?.map(o => ({ ...o }));

      setPageFlagData(editableData?.filter(el => (el.name === 'app-terms-page')))
      setPostFlagData(editableData?.filter(el => (el.name === 'app-terms-post')))

      // console.log(sessionFlagData)

    })
    .catch((error) => {
        console.log(error)
    })
  }

  useEffect(() => {

      fetchData()

  }, [])


  return (
    <div onClick={() => handleClose}>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          {/* <Modal.Title>{props.title?props.title: 'Form'}</Modal.Title> */}
          <select style={{width:"150px"}} name='type' value={selected} onChange={handleSelected} className="form-select form-select-sm" aria-label=".form-select-sm example">
            <option value="1">Page</option>
            <option value="2">Post</option>
          </select>
        </Modal.Header>

        <Modal.Body>
          {/* {props.tableForm? props.tableForm : 'Form not available'} */}

          <div className="d-flex justify-content-center my-2 flex-column">
            {selected === 1 ? (
              pageFlagData?.length ? <>
                {/* {pageFlagData[indexNo]?.url? <img
                  alt="ddk"
                  src={url + pageFlagData[indexNo]?.url}
                  className="img-fluid"
                  style={{ maxHeight: '90%', maxWidth: '100%' }}
                />: null} */}
                {pageFlagData[indexNo]?.description? <div style={{textAlign:'center', color:'#16307D'}}>{pageFlagData[indexNo]?.description}</div>: null}
              </> : <><h6 style={{textAlign:'center', color:'#16307D'}}>Sorry Not Available Now</h6></>
            ) : selected === 2 ? (
              postFlagData?.length ? <div className="flex-column mx-auto">
                {/* <img
                  alt="ddk"
                  src={url + postFlagData[indexNo]?.url}
                  className="img-fluid"
                  style={{ maxHeight: '90%', maxWidth: '100%' }}
                /> */}
                {postFlagData[indexNo]?.description? <div style={{textAlign:'center', color:'#16307D'}}>{postFlagData[indexNo]?.description}</div>: null}
              </div> : <><h6 style={{textAlign:'center', color:'#16307D'}}>Sorry Not Available Now</h6></>
            ) : null}
          </div>

        </Modal.Body>

        <Modal.Footer>
          <div>
            {selected === 1 ? 
            <>
              <span onClick={() => setIndexNo(prevIndex => prevIndex > 0 ? prevIndex - 1 : (pageFlagData.length - 1))} style={{color:'#16307D', border:"solid 1px #16307D"}} className="badge rounded-circle">
                <i className="fa fa-arrow-left"></i>
              </span>
              <span onClick={() => indexNo < (pageFlagData.length - 1)? setIndexNo(indexNo+1) : setIndexNo(0)} style={{color:'green', border:"solid 1px #16307D"}} className="badge rounded-circle mx-2">
                <i className="fa fa-arrow-right"></i>
              </span>
              <span onClick={handleClose} style={{color:'red', border:"solid 1px #16307D"}} className="badge rounded-circle">
                <i className="fa fa-close"></i>
              </span>
            </> 
            : selected === 2 ? 
            <>
              <span onClick={() => setIndexNo(prevIndex => prevIndex > 0 ? prevIndex - 1 : (postFlagData.length - 1))} style={{color:'#16307D', border:"solid 1px #16307D"}} className="badge rounded-circle">
                <i className="fa fa-arrow-left"></i>
              </span>
              <span onClick={() => indexNo < (postFlagData.length - 1)? setIndexNo(indexNo+1) : setIndexNo(0)} style={{color:'green', border:"solid 1px #16307D"}} className="badge rounded-circle mx-2">
                <i className="fa fa-arrow-right"></i>
              </span>
              <span onClick={handleClose} style={{color:'red', border:"solid 1px #16307D"}} className="badge rounded-circle">
                <i className="fa fa-close"></i>
              </span>
            </> 
            : null}
          </div>
        </Modal.Footer>
      </Modal>
    </div>
  );

})


export default TermsModal