import axios from 'axios'
import { createAsyncThunk } from '@reduxjs/toolkit';
import { domainUrl } from '../dummyData';

let url = 'https://emychatserver.herokuapp.com';
url = 'http://localhost:3003';
url = "http://127.0.0.1:8000/api"

url =  domainUrl

export const createPageUser= createAsyncThunk(
  'page_users/create',
  async (
    reqData, 
    { getState, rejectWithValue }) => {
    try {
      // get user data from store
      const { persist } = getState()
      
      // configure authorization header with user's token
      const config = {
        headers: {
          Authorization: `Bearer ${persist.user.userToken}`,
          'Content-Type': 'multipart/form-data',
        },
      }

      // console.log('ax', firstName)
      const { data } = await axios.post(
        `${url}/page_users`,
        reqData,
        config
      )

      return data

    } catch (error) {
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message)
      } else {
        return rejectWithValue(error.message)
      }
    }
  }
)

export const editPageUser= createAsyncThunk(
  'page_users/edit',
  async (
    reqData, 
    { getState, rejectWithValue }) => {
    try {
      // get user data from store
      const { persist } = getState()
      
      // configure authorization header with user's token
      const config = {
        headers: {
          Authorization: `Bearer ${persist.user.userToken}`,
          'Content-Type': 'multipart/form-data',
        },
      }

      // console.log('ax', firstName)
      const { data } = await axios.put(
        `${url}/page_users/${reqData.id}`,
        reqData,
        config
      )

      return data

    } catch (error) {
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message)
      } else {
        return rejectWithValue(error.message)
      }
    }
  }
)

export const deletePageUser= createAsyncThunk(
  'page_users/delete',
  async (pageUserId, { getState, rejectWithValue }) => {
    try {
      // get user data from store
      const { persist } = getState()
      
      // configure authorization header with user's token
      const config = {
        headers: {
          Authorization: `Bearer ${persist.user.userToken}`,
        },
      }

      const { data } = await axios.delete(`${url}/page_users/${pageUserId}`, config)
      return data
    } catch (error) {
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message)
      } else {
        return rejectWithValue(error.message)
      }
    }
  }
)

export const showPageUser= createAsyncThunk(
  'page_users/show',
  async (pageUserId, { getState, rejectWithValue }) => {
    try {
      // get user data from store
      const { persist } = getState()
      
      // configure authorization header with user's token
      const config = {
        headers: {
          Authorization: `Bearer ${persist.user.userToken}`,
        },
      }

      const { data } = await axios.get(`${url}/page_users/${pageUserId}`, config)
      return data
    } catch (error) {
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message)
      } else {
        return rejectWithValue(error.message)
      }
    }
  }
)

export const getPageUsers = createAsyncThunk(
  'page_users/get',
  async (arg, { getState, rejectWithValue }) => {
    try {
      // get user data from store
      const { persist } = getState()
      
      // configure authorization header with user's token
      const config = {
        headers: {
          Authorization: `Bearer ${persist.user.userToken}`,
        },
      }

      const { data } = await axios.get(`${url}/page_users`, config)
      return data
    } catch (error) {
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message)
      } else {
        return rejectWithValue(error.message)
      }
    }
  }
)