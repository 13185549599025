import React, { forwardRef, useImperativeHandle, useState, useEffect } from 'react';

import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import './Custom.css'

import { useDispatch, useSelector } from 'react-redux'
import {getFlags} from '../../routes/FlagAction';

import { domain } from '../../dummyData';
let url =  domain;

const TipsModal = forwardRef((props, ref) => {
  const [show, setShow] = useState(false);

  const handleOpen = () => {
    setShow(true)
  };

  const handleClose = () => {
    setShow(false)
  };


  useImperativeHandle(
    ref,
    () => ({
      handleOpen() {handleOpen()},
      handleClose() {handleClose()},
    }),
  )

  const handleSubmit = () => {
    props.handleSubmit()
  };

  const dispatch = useDispatch();

  const [selected, setSelected] = useState(1);
  const [indexNo, setIndexNo] = useState(0);

  const [msgFlagData, setMsgFlagData] = useState([]);

  const handleSelected = (e) => {
    // setSelected(e.target.value)
    setIndexNo(1);
    setSelected(parseInt(e.target.value, 10));
    // console.log(selected)
  };

  const fetchData = () => {
    dispatch(getFlags())
    .then(response =>{
      let resData = response.payload.data;
      const editableData = resData.map(o => ({ ...o }));

      setMsgFlagData(editableData?.filter(el => (el.name === 'app-msg')))

    })
    .catch((error) => {
        console.log(error)
    })
  }

  useEffect(() => {

      fetchData()

  }, [])


  return (
    <div onClick={() => handleClose}>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Messages</Modal.Title>
          {/* <select style={{width:"150px"}} name='type' value={selected} onChange={handleSelected} className="form-select form-select-sm" aria-label=".form-select-sm example">
            <option value="1">Subscription</option>
            <option value="2">Sessions</option>
            <option value="3">Players</option>
            <option value="4">Coach</option>
          </select> */}
        </Modal.Header>

        <Modal.Body>
          {/* {props.tableForm? props.tableForm : 'Form not available'} */}

          <div className="d-flex justify-content-center my-2 flex-column">
            {selected === 1 ? (
              msgFlagData?.length ? <>
                <img
                  alt="ddk"
                  src={url + msgFlagData[indexNo]?.url}
                  className="img-fluid"
                  style={{ maxHeight: '90%', maxWidth: '100%' }}
                />
                {msgFlagData[indexNo]?.description? 
                <div dangerouslySetInnerHTML={{__html: msgFlagData[indexNo]?.description}} style={{textAlign:'center', color:'#16307D'}}>
                  {/* {msgFlagData[indexNo]?.description.split('<br/>').map(function (rtext, index) {
                      return <p key={index}>{ rtext }</p>; 
                  })} */}
                  {/* {msgFlagData[indexNo]?.description} */}
                </div>: null}
              </> : <><h6 style={{textAlign:'center', color:'#16307D'}}>Sorry Not Available Now</h6></>
            ) : null}
          </div>

          {/* <div className="d-flex justify-content-center my-2">
            {sessionFlagData?.length? 
            <img
              alt="ddk"
              src={url+sessionFlagData[indexNo]?.url}
              // className="img-fluid rounded-circle"
              className="img-fluid"
              style={{ maxHeight: '90%', maxWidth: '90%'}}
            />:
            <h6>Empty</h6>}
          </div> */}

        </Modal.Body>

        <Modal.Footer>
          <div>
            {selected === 1 ? 
            <>
              <span onClick={() => setIndexNo(prevIndex => prevIndex > 0 ? prevIndex - 1 : (msgFlagData.length - 1))} style={{color:'#16307D', border:"solid 1px #16307D"}} className="badge rounded-circle">
                <i className="fa fa-arrow-left"></i>
              </span>
              <span onClick={() => indexNo < (msgFlagData.length - 1)? setIndexNo(indexNo+1) : setIndexNo(0)} style={{color:'green', border:"solid 1px #16307D"}} className="badge rounded-circle mx-2">
                <i className="fa fa-arrow-right"></i>
              </span>
              <span onClick={handleClose} style={{color:'red', border:"solid 1px #16307D"}} className="badge rounded-circle">
                <i className="fa fa-close"></i>
              </span>
            </> 
            : null}
          </div>
          
        </Modal.Footer>
      </Modal>
    </div>
  );

})


export default TipsModal