import React, { useEffect, useState, useRef } from 'react';
import "./home.css"
import PageHeader from "./PageHeader"
import ErrorDialog from '../components/Alert/ErrorDialog';
import CustomAlert from '../components/Alert/CustomAlert';

import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, Link } from 'react-router-dom'
import {resetPassword} from '../routes/UserAction';

const ResetPasswordPage = () => {
  const { loading, user, error, success, successMessage } = useSelector((state) => state.persist.user)
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const errorDialogRef = useRef()
  const customAlertRef = useRef()
  const [errorMsg, setErrorMsg] = useState('');

  const [rememberMe, setRememberMe] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const handleRememberMe = () => {
    setRememberMe(!rememberMe);
  }

  const handleShowPassword = () => {
    setShowPassword(!showPassword);
  }

  const handleSubmit = (event) => {
    event.preventDefault();
    setSubmitting(true);

    const data = new FormData(event.currentTarget);

    if (((data.get('email') !== '') && (data.get('email').length > 5)) && ((data.get('password') !== '') && (data.get('password').length > 5))) {
      dispatch(resetPassword({email:data.get('email'), password: data.get('password')}))
      .then(response =>{

        if (error) {
          setSubmitting(false);
          // console.log('er', error)
          setErrorMsg(error);
          // customAlertRef.current.handleOpen(error, 'danger');
          
        } else {
          if (successMessage !== '') {
            setErrorMsg(successMessage);
            // errorDialogRef.current.handleOpen();
            // customAlertRef.current.handleOpen(successMessage, 'success');
            // navigate('/landing_page')
          }
          setSubmitting(false);
        }
        
      }).catch((error) => {
        setSubmitting(false);
        // console.log(error)
        setErrorMsg(error);
        // errorDialogRef.current.handleOpen();
        customAlertRef.current.handleOpen(error, 'danger');
      });
    } else {
      setSubmitting(false);
      customAlertRef.current.handleOpen('Please enter credentials correctly', 'danger');
    }
  };

  useEffect(() => {
    if (error) {
      customAlertRef.current.handleOpen(error, 'danger');
      
    } else {
      if (successMessage !== '') {
        customAlertRef.current.handleOpen(successMessage, 'success');
      }
      setSubmitting(false);
    }
  }, [error, successMessage])

  useEffect(() => {

    const userToken = localStorage.getItem('userToken')
    ? localStorage.getItem('userToken')
    : null
    if (userToken !== null) {
      navigate('/landing_page')
    }
  }, [navigate, user, success])

  return (
    <>
      <CustomAlert ref={customAlertRef} status={'danger'} msg={errorMsg}/>
      <div className="main-box b-screen overflow-y-auto" style={{backgroundColor:'#16307D', height:'100vh'}}>

        <PageHeader url={'/register'} txt={'Register'} txt1={"Don't have an account"} screen={'big'}/>

        {loading ? (
          <div className="loader-container">
              <div className="spinner"></div>
              {/* <h6 style={{color: 'white', textAlign:'center'}}>loading please wait...</h6> */}
          </div>
        ) :
        <div className="d-flex justify-content-center my-5">
          <div className="rounded-4 d-flex flex-column justify-content-center" style={{width:'40vw', height:'60vh', backgroundColor:'#FFFFFF', border:"solid 2px #F8BC16"}}>
            <h6 style={{color:'#16307D', textAlign:'center'}} className="mx-auto mt-2 mb-0">Welcome To DigitalGH</h6>
            <p style={{maxWidth:'30vw', color:'#16307D', textAlign:'center'}} className="mx-auto my-1">Your digital inclusivity in the governance.</p>
            
            {/* <ErrorDialog ref={errorDialogRef} msg={errorMsg}/> */}
            {/* <CustomAlert ref={customAlertRef} status={'danger'} msg={errorMsg}/> */}
            <form onSubmit={handleSubmit} className="d-flex flex-column justify-content-center my-auto mx-4 px-4">
              <div className="input-group my-1">
                <span style={{backgroundColor:'#F8BC16', border:"solid 1px #16307D"}} className="input-group-text text-white">
                  <i className="fa fa-envelope"></i>
                </span>
                <input style={{backgroundColor:'#E1F2FE', border:"solid 1px #16307D", color:'#16307D'}} type="email" name="email" id="email" autoComplete="email" className="form-control" placeholder="Enter Email"></input>
              </div>

              <div className="input-group my-1">
                <span style={{backgroundColor:'#F8BC16', border:"solid 1px #16307D"}} className="input-group-text text-white">
                  <i className="fa fa-lock"></i>
                </span>
                <input style={{backgroundColor:'#E1F2FE', border:"solid 1px #16307D", color:'#16307D'}} type={showPassword ? "text" : "password"} name="password" id="password" className="form-control" placeholder="Enter Password"></input>
                <span style={{backgroundColor:'#F8BC16', border:"solid 1px #16307D"}} className="input-group-text text-white">
                  {showPassword?<i onClick={handleShowPassword} className="fa fa-eye-slash"></i>
                  :<i onClick={handleShowPassword} className="fa fa-eye"></i>}
                </span>
              </div>

              <div className="row mb-1">
                <div className="col d-flex justify-content-center">
                  <div className="form-check">
                    <input style={{backgroundColor:'#F8BC16', color:'#16307D'}} className="form-check-input" type="checkbox" value="" id="remember_me" checked={rememberMe} onChange={handleRememberMe} />
                    <h6 style={{color:'#16307D'}} className="form-check-label"> Stay Connected</h6>
                  </div>
                </div>

              </div>

              <button style={{color:'#FFFFFF', backgroundColor:'#F8BC16', width:'120px', border:"solid 1px #16307D"}} type="submit" className="btn btn-sm btn-block rounded-4 mx-auto">Sign in</button>
              <Link className="mx-auto" to={'/register'} style={{color:'#16307D'}}>Don't have an account? Register</Link>
            </form>
          
          </div>
        </div>}

      </div>

      {/* mobile */}
      <div className="main-box m-screen overflow-y-auto" style={{backgroundColor:'#16307D', height:'100vh'}}>

        <PageHeader url={'/register'} txt={'Register'} txt1={"Don't have an account"} screen={'mobile'}/>

        {loading ? (
          <div className="loader-container">
              <div className="spinner"></div>
              {/* <h6 style={{color: 'white', textAlign:'center'}}>loading please wait...</h6> */}
          </div>
        ) :
        <div className="d-flex justify-content-center my-5">
          <div className="rounded-4 d-flex flex-column justify-content-center py-2 mx-3" style={{width:'100%', height:'100%', backgroundColor:'#FFFFFF', border:"solid 2px #F8BC16"}}>
            <h6 style={{color:'#16307D', textAlign:'center'}} className="mx-auto mt-2 mb-0">Welcome To DigitalGH</h6>
            <p style={{maxWidth:'50vw', color:'#16307D', textAlign:'center'}} className="mx-auto my-1">Your digital inclusivity in the governance.</p>
            
            {/* <ErrorDialog ref={errorDialogRef} msg={errorMsg}/> */}
            {/* <CustomAlert ref={customAlertRef} status={'danger'} msg={errorMsg}/> */}
            <form onSubmit={handleSubmit} className="d-flex flex-column justify-content-center my-auto mx-4">
              <div className="input-group my-1">
                <span style={{backgroundColor:'#F8BC16', border:"solid 1px #16307D"}} className="input-group-text text-white">
                  <i className="fa fa-envelope"></i>
                </span>
                <input style={{backgroundColor:'#E1F2FE', border:"solid 1px #16307D", color:'#16307D'}} type="email" name="email" id="email" autoComplete="email" className="form-control" placeholder="Enter Email"></input>
              </div>

              <div className="input-group my-1">
                <span style={{backgroundColor:'#F8BC16', border:"solid 1px #16307D"}} className="input-group-text text-white">
                  <i className="fa fa-lock"></i>
                </span>
                <input style={{backgroundColor:'#E1F2FE', border:"solid 1px #16307D", color:'#16307D'}} type={showPassword ? "text" : "password"} name="password" id="password" className="form-control" placeholder="Enter Password"></input>
                <span style={{backgroundColor:'#F8BC16', border:"solid 1px #16307D"}} className="input-group-text text-white">
                  {showPassword?<i onClick={handleShowPassword} className="fa fa-eye-slash"></i>
                  :<i onClick={handleShowPassword} className="fa fa-eye"></i>}
                </span>
              </div>

              <div className="row mb-1">
                <div className="col d-flex justify-content-center">
                  <div className="form-check">
                    <input style={{backgroundColor:'#F8BC16', color:'#16307D'}} className="form-check-input" type="checkbox" value="" id="remember_me" checked={rememberMe} onChange={handleRememberMe} />
                    <h6 style={{color:'#16307D'}} className="form-check-label"> Stay Connected</h6>
                  </div>
                </div>
              </div>

              <button disabled={submitting} style={{color:'#FFFFFF', backgroundColor:'#F8BC16', width:'120px', border:"solid 1px #16307D"}} type="submit" className="btn btn-sm btn-block rounded-4 mx-auto">Sign in</button>
              <Link className="mx-auto" to={'/register'} style={{color:'#16307D'}}>Don't have an account? Register</Link>
            </form>
          
          </div>
        </div>}

      </div>
    </>
  )
}

export default ResetPasswordPage
