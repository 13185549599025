import React from "react";

import { timeFormat, domain } from "../../dummyData";
import { Link, useNavigate } from "react-router-dom";
import { useSelector } from 'react-redux'

const PageCard = (props) => {
    const { user } = useSelector((state) => state.persist.user)
    const userData = user;

    const navigate = useNavigate();
    const navigateToRoute = (url) => {
        navigate(url);
    };

    return (
        <>
            <div onClick={() => {navigateToRoute(`/page?id=${props.data?.uuid}&&code=${props.data?.user?.uuid}`)}} className="row mx-2">
                <div className="card text-bg-white mb-3 mx-2" style={{maxWidth:"18rem"}}>
                    <div className="d-flex justify-content-center my-2">
                        <img style={{width:'20vh', height:'20vh', marginTop:'10px'}} alt='ddk' src={props.data?.image? domain+props.data.image: "./assets/images/1.jpg"} className="rounded-circle"></img>
                    </div>
                    <div className="d-flex justify-content-center my-2">
                        {(props.data.user?.user_id === userData.id)? 
                            <h6 style={{marginBottom:'0', marginTop:'0', color:'#000000', fontSize:14}}>You</h6> 
                        : 
                            <h6 style={{marginBottom:'0', marginTop:'0', color:'#000000', fontSize:14}}>{props.data.user? props.data?.user?.first_name: ''} {props.data?.user? props.data?.user?.last_name: ''}</h6>
                        }
                    </div>
                    <div className="d-flex justify-content-center my-2">
                        <h6 style={{marginBottom:'0', marginTop:'0', color:'#F9CC2E', fontSize:12}}>Posted {timeFormat(props.data?.user?.created_at)}</h6>
                    </div>

                    {/* <h6 className="mx-auto" style={{marginBottom:'0', marginTop:'0', color:'#ec2f2f', marginLeft:'10px', fontSize: 12, fontStyle: 'italic', textAlign: 'center'}}>
                        {props.data?.user?.city? props.data?.user?.city+',': ''} {props.data?.user?.state? props.data?.user?.state+',': ''} {props.data?.user?.country}
                    </h6> */}
                    <h6 className="mx-auto" style={{marginBottom:'0', marginTop:'0', color:'#ec2f2f', marginLeft:'10px', fontSize: 12, fontStyle: 'italic', textAlign: 'center'}}>
                        {props.data?.title? props.data?.title: ''}
                    </h6>

                    {/* <div className="d-flex justify-content-center my-2">
                        <h6 style={{marginBottom:'0', marginTop:'0', color:'#000000', fontSize:12}}>Needs help in:</h6>
                    </div> */}
                    {/* <div className="d-flex justify-content-center my-2">
                        <div className="d-flex flex-row">
                            {props.data?.expertise?.length > 0 ?
                                props.data?.expertise?.slice(-2)?.map((row) => (
                                    <span key={row.id} style={{marginRight:'5px', backgroundColor:'#E1F2FE', color:'#ec2f2f', border:"solid 1px #ec2f2f"}} className="badge py-2 my-1 rounded-pill">{row.expertise.name}</span>
                                ))
                            : <h5 style={{color:'#ec2f2f', fontSize: 12}}>No Expertise...</h5>}
                        </div>
                    </div> */}
                    <span onClick={() => {navigateToRoute(`/page?id=${props.data?.uuid}&&code=${props.data?.user?.uuid}`)}} style={{backgroundColor:'#ec2f2f', border:"solid 1px #ec2f2f", height:'30px', marginTop:'10px'}} className="badge py-2 px-1 my-1 rounded">
                        <i style={{marginRight:'10px'}} className="fa-solid fa-message"></i>
                        View Page
                    </span>
                </div>
            </div>
        </>
    );

}

export default PageCard