import React, { useState, useRef, useEffect } from "react"

import { updateKeyWord } from '../../redux/UserSlice';

import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux'
import {logout, userSub} from '../../routes/UserAction';
import './header.css'
import Guide from "../../Dashboard/Form/Guide";
import TipsModal from '../../components/Modal/TipsModal';

// import {getNotifications, deleteNotification, editNotification} from '../../routes/NotificationAction';
import {getFlags} from '../../routes/FlagAction';

import { domain, reduceText } from '../../dummyData';
import CreatePageModal from "../Modal/PageModal";
let url =  domain;



const Header = (props) => {

  const dispatch = useDispatch();
  // const userData = useSelector((state) => state.persist.user);
  const { searchWord, loading, user, error, success, successMessage } = useSelector((state) => state.persist.user)
  const { newChat } = useSelector((state) => state.chat)
  const userData = user;

  const handleClick = () => { 
    dispatch(logout()); 
  };

  // const handleSearch = (e) => {
  //   dispatch(updateKeyWord(e.target.value))
  // }

  const [searchText, setSearchText] = useState('');
  const handleSearch = (e) => {
    setSearchText(e.target.value)
  }

  const handleSearchS = () => {
    // console.log('cahaa')
    dispatch(updateKeyWord(searchText))
  }

  const handleSearchC = () => {
    setSearchText('')
    dispatch(updateKeyWord(''))
  }

  const navigate = useNavigate();
  const navigateToRoute = (url) => {
    // 👇️ navigate to /contacts
    navigate(url);
  };


  const fetchNotification = () => {
    // dispatch(getNotifications())
    // .then(response =>{
    //   let resData = response?.payload?.data;
    //   const editableData = resData?.map(o => ({ ...o }));
    //   setNotificationData(editableData);
    // })
    // .catch((error) => {
    //     console.log(error)
    // })
  }

  const [userSubState, setUserSubState] = useState(false);
  const fetchUserSubStatus = () => {
    // dispatch(userSub())
    // .then(response =>{
    //   let resData = response?.payload?.data;
    //   setUserSubState(resData);

    //   // console.log('sub', userSubState)
    // })
    // .catch((error) => {
    //     console.log(error)
    // })
  }

  const fetchFlags = () => {
    dispatch(getFlags())
    .then(response =>{
      let resData = response?.payload?.data;
      const editableData = resData?.map(o => ({ ...o }));

      setScrollFlagData(editableData?.filter(el => (el.name === 'app-scroll')))

      // console.log(sessionFlagData)

    })
    .catch((error) => {
        console.log(error)
    })
  }
  
  const [notificationData, setNotificationData] = useState([]);
  const [scrollFlagData, setScrollFlagData] = useState([]);
  useEffect(() => {
    fetchUserSubStatus();
    fetchNotification();
    fetchFlags();

  }, [navigate, success])

  const [onMobileState, setOnMobileState] = useState(false);

  const mobileMenuClickHandler = (menu) => {
    setOnMobileState(onMobileState => !onMobileState)
  };

  const handleRead = (row) => {
    // console.log(row)

    // dispatch(editNotification({...row, status:1}))
    // .then(response =>{
    //   fetchNotification();
    // })
    // .catch((error) => {
    //     console.log(error)
    // })

    let url = window.location.href;
    navigateToRoute(row.link)
  }

  const handleDelete = (id) => {
    // dispatch(deleteNotification(id))
    // .then(response =>{
    //   fetchNotification();
    // })
    // .catch((error) => {
    //     console.log(error)
    // })
    // console.log(id)
  }

  const handleSubmit = () => {
    guideRef.current.handleSubmit();
  };

  function sleep(ms) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }

  const handleResponse = (success) => {
    // fetchData();
    // sleep(0).then(() => {
    //     modalRef.current.handleClose();
    //     setAction('Add')
    // });
  }

  const guideRef = useRef()

  const pageRef = useRef()
  const modalRef = useRef()
  const handleOpen = () => {
    modalRef.current.handleOpen();
    
  };
  const handleOpenPage = () => {
    pageRef.current.handleOpen();
    
  };

  return (
    <>
    {/* <header> */}

    <header className="section-header fixed-top">
      <TipsModal 
        title={' Tips'}
        tableForm={<Guide 
          // handleResponse={handleResponse}
          ref={guideRef} 
        />} 
        ref={modalRef}
        // handleSubmit={''}
        
      />
      <CreatePageModal 
        title={' Create Group'}
        ref={pageRef}
        // handleSubmit={''}
        
      />

      <nav className="navbar navbar-light navbar-expand-lg p-2 bg-white">
        <div className="container-fluid">
          
          <ul className="navbar-nav d-flex align-items-center flex-row">
            <button style={{border:'white solid 1px'}} onClick= {mobileMenuClickHandler} className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNavDropdown" aria-controls="navbarNavDropdown" aria-expanded="false" aria-label="Toggle navigation">
              {/* <span style={{color:'#ec2f2f'}} className="navbar-toggler-icon"></span> */}
              <i style={{color:'#ec2f2f'}} className="fa fa-bars"></i>
            </button>
            {!onMobileState? 
              <li className="nav-item mx-2">
                <div className="d-flex">
                  <Link to={'/landing_page'} className="">
                    <img alt='ddk' src="./assets/images/logo/logo.png" className="" width="50"></img>
                  </Link>
                    
                </div>
              </li>
              : 
              <></>}
            
            
          </ul>

          <div className="collapse navbar-collapse px-5" id="navbarNavDropdown">

            <ul className="navbar-nav">
              <li className="nav-item mx-2">
                <Link style={{textDecorationLine:props.page==='home'? "underline": '', textDecorationColor:"#F9CC2E", textDecorationThickness:3}} to={'/landing_page'} className="nav-link active" aria-current="page"><h6 style={{color:'#ec2f2f'}}>Home</h6></Link>
                {/* <a className="nav-link active" aria-current="page" href="s#">Electronics</a> */}
              </li>
              <li className="nav-item mx-1">
                <Link style={{textDecorationLine:props.page==='post'? "underline": '', textDecorationColor:"#F9CC2E", textDecorationThickness:3}} to={'/posts'} className="nav-link" href="s#"><h6 style={{color:'#ec2f2f'}}>posts</h6></Link>
              </li>
              <li className="nav-item mx-1">
                <Link style={{textDecorationLine:props.page==='page'?"underline": '', textDecorationColor:"#F9CC2E", textDecorationThickness:3}} to={'/pages'} className="nav-link"><h6 style={{color:'#ec2f2f'}}>pages</h6></Link>
              </li>

              <li className="nav-item mx-1">
                <Link style={{textDecorationLine:props.page==='ghana_bas'?"underline": '', textDecorationColor:"#F9CC2E", textDecorationThickness:3}} to={'/ghana_ba_shares'} className="nav-link" data-abc="true"><h6 style={{color:'#ec2f2f'}}>GhanaBaS</h6></Link>
              </li>
              <li className="nav-item mx-1">
                <Link style={{textDecorationLine:props.page==='chat'? "underline": '', textDecorationColor:"#F9CC2E", textDecorationThickness:3}} to={'/chat'} className="nav-link" data-abc="true">
                  <h6 className="position-relative" style={{color:'#ec2f2f'}}>
                  Chat
                    {newChat?
                    <span style={{backgroundColor:"#F9CC2E", color:"#ec2f2f"}} className="position-absolute bottom-0 start-100 translate-middle badge rounded-pill">
                      {newChat > 9? "9+": newChat}
                      <span className="visually-hidden">unread messages</span>
                    </span>:null}
                  </h6>
                </Link>
              </li>
              {/* {userData?.userType === 1 ?
                <li className="nav-item mx-1">
                  <Link style={{textDecorationLine:props.page==='dashboard'? "underline": '', textDecorationColor:"#F9CC2E", textDecorationThickness:3}} to={'/index'} className="nav-link" data-abc="true"><h6 style={{color:'#ec2f2f'}}>Dashboard</h6></Link>
                </li>: <></>} */}
            </ul>
          </div>

          <span onClick={() => window.open("https://paystack.com/pay/nanafrimpomaa", "_blank")} className="border-blue input-group-text bg-white text-blue mr-3">
            <span className="text">Donate</span>
          </span>
          
          {/* {!loading? <>{userData?.userType !== 4?
          !userSubState? <Link to={'/pricing'} className="mr-1" style={{textDecorationLine:'none'}}>
            <span className="border-blue input-group-text bg-white text-blue">
              <span className="text">Subscribe</span>
            </span>
          </Link>: null : null}</>: null} */}

          {/* <i style={{fontSize: 18, color:'#ec2f2f'}} onClick={handleOpen} className="fa fa-question-circle ml-2"></i> */}

          {!onMobileState? 
            <ul className="navbar-nav d-flex align-items-center flex-row">
              <i style={{fontSize: 24, color:'#ec2f2f'}} onClick={handleOpenPage} className="fa fa-add mr-1"></i>

              <i style={{fontSize: 24, color:'#ec2f2f'}} onClick={handleOpen} className="fa fa-bell mr-1"></i>

              {/* <li className="nav-item mx-1" >
                <div className="d-flex dropstart">
                  <i style={{color:'#ec2f2f'}} className="fa-solid fa-bell position-relative" data-bs-toggle="dropdown" aria-expanded="false" data-bs-auto-close="outside" data-bs-offset="10,20">
                    {notificationData?.filter(el => (el.status == 0))?.length>0?
                      <span style={{backgroundColor:"#F9CC2E", color:"#ec2f2f"}} className="position-absolute bottom-0 start-100 translate-middle badge rounded-pill">
                        {notificationData?.filter(el => (el.status == 0))?.length>9? "9+" : notificationData?.filter(el => (el.status == 0))?.length}
                        <span className="visually-hidden">unread messages</span>
                      </span>: null}
                  </i>
                  <div className="dropdown-menu p-1" style={{
                    position: 'absolute', 
                    width: "60vw",
                  }}
                  >
                    <ul className="list-group">
                    {notificationData?.length> 0? notificationData.map((row) => (
                      <li key={row.id} className="list-group-item d-flex justify-content-between align-items-center">
                        <h6>
                          {reduceText(row.description, 30, '...')}
                        </h6>
                        <div className="d-flex flex-column">
                          {row.status === 0? <i onClick={() => handleRead(row)} style={{color:'#ec2f2f'}} className="fa fa-eye"></i>:<i onClick={() => handleRead(row)} style={{color:'grey'}} className="fa fa-eye"></i>}
                          <i onClick={() => handleDelete(row.id)} style={{color:'red'}} className="fa fa-trash mt-1"></i>
                        </div>
                        
                      </li>
                    )): "No data..."}
                      
                    </ul>
                  </div>
                </div>
              </li> */}

              <li className="nav-item mx-2">
                <div className="d-flex">
                  <Link to={(userData?.name !== null? `/edit_profile?id=${userData?.uuid}`: '/landing_page')}>
                    <img style={{border:'#ec2f2f 3px solid', width:'10vw', height:'10vw', maxWidth:'30px', maxHeight:'30px'}} alt='ddk' src= {userData?.image? url+userData?.image:"./assets/images/1.jpg"} className="rounded-circle"></img>
                  </Link>
                </div>
              </li>
              {(userData?.name !== '' || userData?.name !== null)?<li className="nav-item mx-1">
                <div className="">
                  <button onClick={handleClick} style={{backgroundColor:'#ec2f2f', border:'#F9CC2E 1px solid', color:'#F9CC2E'}} className="btn btn-sm b-screen" type="button">Logout</button>
                  <i onClick={handleClick} style={{color:'#ec2f2f'}} className="m-screen fa fa fa-sign-in"></i>
                </div>
              </li>: <></>}
            
            </ul>
          : 
            <></>
          }
          
        </div> 
      </nav>

      {/* <section className="border-bottom bg-white"> */}
        {props.noSearch? <></>:
        <div className="px-3">
          <div className="row p-2 pt-3 pb-3 d-flex align-items-center">

            <div className="col-md-6">
              {/* <div className="input-group alert-div">
                <span className="col-10 border-warning input-group-text bg-warning text-white">
                </span>
                <button className="col-2 btn btn-danger text-white">Close</button>
              </div> */}

              <div className="input-group alert-div">
                <span style={{backgroundColor:'#E1F2FE', border:"solid 1px #ec2f2f"}} className="col-10 input-group-text text-white">
                  <marquee style={{color:'#ec2f2f'}} width="100%" direction="left">
                    {scrollFlagData? scrollFlagData.map((row) => ('          '+row.value+'          ')): " ----<<  Hey make sure you download our app for easy access!!!.  >>----"}
                  </marquee>
                </span>
                <button style={{backgroundColor:'#ec2f2f', border:"solid 1px #ec2f2f"}} className="col-2 btn text-white"><i className="fa fa-eye"></i></button>
              </div>
              

              {/* <div className="d-flex form-inputs">
                <input className="form-control" type="text" placeholder="Search any product..."></input>
                <i className="fa fa-search"></i>
              </div> */}
            </div>
            <div className="col-md-6 d-lg">
              <div className="input-group">
                <span onClick={handleSearchS} style={{backgroundColor:'#ec2f2f', border:"solid 1px #ec2f2f"}} className="input-group-text text-white">
                  <i className="fa fa-search"></i>
                </span>
                <input value={searchText} onChange={handleSearch} style={{backgroundColor:'#E1F2FE', border:"solid 1px #ec2f2f"}} type="text" className="form-control"></input>
                <button onClick={handleSearchC} style={{backgroundColor:'#ec2f2f', border:"solid 1px #ec2f2f"}} className="btn text-white"><i className="fa fa-remove"></i></button>
              </div>
            </div>
              
            
          </div>
        </div>}
      {/* </section> */}
    </header>
    </>
  )
}

export default Header
