import React, { useState, useRef, useEffect } from 'react';
import './banner.css';

import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux'
import {deleteAccount} from '../../routes/UserAction';
// import {createStripeAccount, viewStripeAccount} from '../../routes/PaymentAction';
// import { deleteVisibility, editVisibility, createVisibility } from "../../routes/VisibilityAction";

import CustomAlert from '../Alert/CustomAlert';

import { timeFormat, timeTable, domain } from "../../dummyData";
import Locations from '../Location/Locations.json'

const PostBannerMy = (props) => {
    const dispatch = useDispatch();

    const { loading, user, error, successMessage } = useSelector((state) => state.persist.user)
    const userData = user;

    const handleDeleteAccount = () => {deleteAccountReq()}

    const navigate = useNavigate();
    const navigateToRoute = (url) => {
        navigate(url);
    };

    const customAlertRef = useRef()

    const handleStripeAccount = () => {
        // dispatch(createStripeAccount())
        // .then(response =>{
            
        // })
        // .catch((error) => {
        //     console.log(error)
        // })
        
    }

    const deleteAccountReq = () => {
        // dispatch(deleteAccount())
        // .then(response =>{
        //   navigate('/login')
        // })
        // .catch((error) => {
        //     console.log(error)
        // })
    }

    const [showVForm, setShowVForm] = useState(false);

    const [visibilityFormData, setVisibilityFormData] = useState({
        description: '',
        state: '',
        city: '',
        long: '',
        lat: '',
        status: 0,
    });

    const [stateData, setStateData] = useState(
        Locations.find((c) => c.name === userData?.country).states
    );
    const [cityData, setCityData] = useState([]);

    const handleChangeState = event => {
        getState(event.target.value.split("#")[0]);
        setVisibilityFormData({...visibilityFormData, state:event.target.value.split("#")[0], long:event.target.value.split("#")[1], lat:event.target.value.split("#")[2]});

        let availableState = Locations.find((c) => c.name === userData?.country);
        let availableCities = availableState?.states?.find(
          (s) => s.name === event.target.value.split("#")[0]
        );
        setCityData(availableCities.cities)
    }

    const [stVal, setStVal] = useState('');
    const getState = (name) => {
        if (name) {
            let availableState = Locations.find((c) => c.name === userData?.country);
            let availableCities = availableState?.states?.find(
                (s) => s.name === name
            );
            setStVal(availableCities.name+'#'+availableCities.longitude+'#'+availableCities.latitude)
        }
        
    }

    const handleChangeCity = event => {
        setVisibilityFormData({...visibilityFormData, city:event.target.value.split("#")[0], long:event.target.value.split("#")[1], lat:event.target.value.split("#")[2] });
    }

    const handleVisibilityFormChange = (e) => {
        if (e.target.name === 'status') {
            setVisibilityFormData({ ...visibilityFormData, [e.target.name]: e.target.checked? 0: 1 });
            } else {
            setVisibilityFormData({ ...visibilityFormData, [e.target.name]: e.target.value });
        }
    }

    const handleAddVisibility = () => {
        setShowVForm(!showVForm)
    }

    const triggerRefresh = () => {
        props.fetchCoach();
    }

    const setRow = (row) => {
        const updatedRow = { ...row };
        if (updatedRow.status) {
            updatedRow.status =  updatedRow.status? 1: 0;
        }
        if (updatedRow.state) {
            getState(updatedRow.state);
        }
        
        setVisibilityFormData(updatedRow)
        
    }

    const deleteRow = (row) => {
        // return dispatch(deleteVisibility(row.id))
        // .then(response =>{

        //     triggerRefresh();
        // })
        // .catch((error) => {
        //     console.log(error)
        // })
        
    }

    const submitData = () => {
        // if(sessionFormData.name === '') return
        // return visibilityFormData.id > 0? 
        // dispatch(editVisibility(visibilityFormData))
        // : dispatch(createVisibility(visibilityFormData))
        // .then(response =>{

        //     if (error) {
        //         // console.log('er', error)
        //         customAlertRef.current.handleOpen(error, 'danger');
            
        //     } else {
        //         if (successMessage !== '') {
        //             customAlertRef.current.handleOpen(successMessage, 'success');
        //         }
        //     }
        //     triggerRefresh();
        // })
        // .catch((error) => {
        //     console.log(error)
        //     customAlertRef.current.handleOpen(error, 'danger');
        // })
        
    }

    return (
        <>
            <CustomAlert ref={customAlertRef}/>
            <div className="b-screen">
                <div className="d-flex justify-content-between mx-3 p-3 my-2" style={{
                    backgroundColor:'#E1F2FE', 
                    // border:"solid 1px #16307D", 
                    borderRadius:'10px'
                    }}>
                    <div className="row">
                        <div className="col-3">
                            <img alt='ddk' src= {props.data.user?.image? domain+props.data.user.image:"./assets/images/1.jpg"} className="rounded-circle" width="80" height="80">
                            </img>
                        </div>
                        <div className="col-9">
                            <h6 style={{marginBottom:'0', marginTop:'0', marginLeft:'20px', color:'#16307D', fontSize:20,}}>{props.data?.user?.first_name} {props.data?.user?.last_name}</h6>
                            <h6 style={{marginBottom:'0', marginTop:'0', marginLeft:'20px', color:'#3458C2'}}>
                                <i style={{marginRight:'5px'}} className="fa-solid fa-magnifying-glass"></i>
                                {props.data?.user?.state? props.data?.user?.state+',': ''} {props.data?.user?.country}
                            </h6>
                            <h6 style={{marginBottom:'0', marginTop:'0', marginLeft:'20px', color:'#67BEFA'}}>Joined since: {timeFormat(props.data?.user?.created_at)}</h6>
                            <span onClick={handleDeleteAccount} style={{marginLeft:'20px',  border:"solid 1px #16307D", color:'#16307D'}} className="badge px-3 rounded-pill">
                                <i style={{color:'#16307D', marginRight:'5px'}} className="fa-solid fa-bolt"></i>
                                Delete Acct
                            </span>
                        </div>
                    </div>
                    <div>
                        <h6 style={{marginBottom:'0', marginTop:'0', color:'#16307D', textAlign:'center'}}>Sessions: ({props.data?.bookings?.length})</h6>
                        
                        <div className="d-flex flex-row my-1 justify-content-center">
                            <span onClick={handleAddVisibility} style={{ border:"solid 1px #16307D", color:'#16307D', marginRight:'5px'}} className="badge px-1 py-1 rounded-pill">
                                <i style={{color:'#16307D', fontSize:16}} className="fa-solid fa-eye"></i>
                            </span>
                            <span onClick={() => {navigateToRoute('/chat')}} style={{ border:"solid 1px #16307D", color:'#16307D', marginRight:'5px'}} className="badge px-1 py-1 rounded-pill">
                                <i style={{color:'#16307D', fontSize:16}} className="fa-solid fa-message"></i>
                            </span>
                        </div>
                        
                    </div>
                    <div className="d-flex flex-column">
                        <span style={{backgroundColor:'#16307D', border:"solid 1px #16307D"}} className="badge px-3 py-2 my-1 rounded-pill" onClick={() => {navigateToRoute(`/edit_profile?id=${userData.uuid}`)}}>View Profile</span>
                        <span style={{border:"solid 1px #16307D", color:'#16307D'}} className="badge px-3 py-2 my-1 rounded-pill" onClick={handleStripeAccount}>
                            {
                                props.data?.account === 1 ? "Pay Initiated"
                                : props.data?.account === 2 ? "Pay Deactivated"
                                : props.data?.account === 5 ? "Pay Activated"
                                : props.data?.account === 3 ? "Pending"
                                : "Activate Pay"
                            }
                        </span>
                    </div>
                    
                </div>
            </div>

            <div className="mx-3 p-3 my-2 m-screen" style={{
                backgroundColor:'#E1F2FE', 
                // border:"solid 1px #16307D", 
                borderRadius:'10px'
                }}>
                <div className="row">
                    <div className="d-flex justify-content-center">
                        <img alt='ddk' src= {props.data?.user?.image? domain+props.data?.user.image:"./assets/images/1.jpg"} className="rounded-circle" width="80" height="80">
                        </img>
                    </div>
                </div>
                <div className="d-flex flex-column">
                    {/* <div className="d-flex justify-content-center"> */}
                        <h6 className="d-flex justify-content-center" style={{marginBottom:'0', marginTop:'0', marginLeft:'20px', color:'#16307D', fontSize:20}}>{props.data?.user?.first_name} {props.data?.user?.last_name}</h6>
                        
                        <h6 className="d-flex justify-content-center" style={{marginBottom:'0', marginTop:'0', marginLeft:'20px', color:'#3458C2'}}>
                            <i style={{marginRight:'5px'}} className="fa-solid fa-magnifying-glass"></i>
                            {props.data?.user?.state? props.data?.user?.state+',': ''} {props.data?.user?.country}
                        </h6>
                        <h6 className="d-flex justify-content-center" style={{marginBottom:'0', marginTop:'0', marginLeft:'20px', color:'#67BEFA'}}>Joined since: {timeFormat(props.data?.user?.createdAt)}</h6>
                        
                    {/* </div> */}
                </div>
                <div className="d-flex justify-content-center">
                    <span onClick={handleDeleteAccount} style={{ border:"solid 1px #16307D", maxWidth:'150px'}} className="badge px-2 py-2 my-1 rounded-pill">
                        <i style={{color:'#16307D', marginRight:'5px'}} className="fa-solid fa-bolt"></i>
                        Delete Acct
                    </span>
                </div>
                <div className="row">
                    <h6 className="d-flex justify-content-center" style={{ marginLeft:'', marginBottom:'0', marginTop:'0', color:'#16307D', textAlign:'center'}}>Sessions: ({props.data?.bookings?.length})</h6>
                    
                    <div className="d-flex flex-row my-1 justify-content-center">
                        <span onClick={handleAddVisibility} style={{ border:"solid 1px #16307D", color:'#16307D', marginRight:'5px'}} className="badge px-1 py-1 rounded-pill">
                            <i style={{color:'#16307D', fontSize:16}} className="fa-solid fa-eye"></i>
                        </span>
                        <span onClick={() => {navigateToRoute('/chat')}} style={{ border:"solid 1px #16307D", color:'#16307D', marginRight:'5px'}} className="badge px-1 py-1 rounded-pill">
                            <i style={{color:'#16307D', fontSize:16}} className="fa-solid fa-message"></i>
                        </span>
                    </div>

                </div>

                <div className="d-flex flex-column">
                    <div className="d-flex justify-content-center">
                        <span style={{backgroundColor:'#16307D', border:"solid 1px #16307D", maxWidth:'150px'}} className="badge px-3 py-2 my-1 rounded-pill d-flex justify-content-center" onClick={() => {navigateToRoute(`/edit_profile?id=${userData.uuid}`)}}>View Profile</span>
                    </div>
                    <div className="d-flex justify-content-center">
                        <span style={{border:"solid 1px #16307D", color:'#16307D', maxWidth:'150px'}} className="badge px-3 py-2 my-1 rounded-pill d-flex justify-content-center" onClick={handleStripeAccount}>
                            {
                                props.data?.account === 1 ? "Pay Initiated"
                                : props.data?.account === 2 ? "Pay Deactivated"
                                : props.data?.account === 5 ? "Pay Activated"
                                : props.data?.account === 3 ? "Pending"
                                : "Activate Pay"
                            }
                        </span>
                    </div>
                    
                </div>
                
            </div>

            {showVForm === true? 
                <div className="mx-4 p-3" style={{
                        backgroundColor:'#E1F2FE', 
                        // border:"solid 1px #16307D", 
                        borderRadius:'10px'
                        }}>
                    <h3 className="d-flex justify-content-center">Visibility Form</h3>
                    <div id='visibility-form'>

                    <div className='row'>
                            <div className="mb-3 col-sm-12 col-md-4">
                                <label className="form-label">City</label>
                                <select value={stVal? stVal: ''} name='state' onChange={(e, value) => {handleChangeState(e, value)}} className="form-select form-select-sm" aria-label=".form-select-sm example">
                                    <option value="">Select City</option>
                                    {stateData?.length > 0? stateData.map((state) => {
                                    return <option
                                    key={state.id} 
                                    value={state.name+'#'+state.longitude+'#'+state.latitude}>
                                    {state.name}
                                    </option>
                                    }): null}
                                </select>
                            </div>
                            <div className="mb-3 col-sm-12 col-md-4">
                                <label className="form-label">District</label>
                                <input type="text" value={visibilityFormData.city? visibilityFormData.city: ''} name='city' onChange={handleVisibilityFormChange} className="form-control form-control-sm" id="exampleFormControlInput1" placeholder="district"/>
                            </div>
                            <div className="mb-3 col-sm-12 col-md-4">
                                <label className="form-label">Description</label>
                                <input type="text" value={visibilityFormData.description? visibilityFormData.description: ''} name='description' onChange={handleVisibilityFormChange} className="form-control form-control-sm" id="exampleFormControlInput1" required placeholder="description"/>
                            </div>
                        </div>

                        <div className="d-flex justify-content-center mb-3">
                            <div className="d-flex mx-auto">
                                <div className="form-check form-switch col-3 mx-auto">
                                    <input name='status' className="form-check-input" type="checkbox" role="switch" id="flexSwitchCheckChecked" onChange={handleVisibilityFormChange} checked={visibilityFormData.status === 0? true: false}/>
                                    <label className="form-check-label">Status</label>
                                </div>
                            </div>
                        </div>

                        <div className="d-flex justify-content-center">
                            <div className="d-flex mx-auto">
                                <div className="mb-3 ml-4">
                                    <button onClick={() => handleAddVisibility('alert')} className="btn btn-sm btn-secondary">Close</button>
                                </div>
                                <div className="mb-3 col-4">
                                    <button onClick={() => {submitData('alert')}} className="btn btn-sm btn-primary">Submit</button>
                                </div>
                            </div>
                        </div>

                        <div className="card shadow mb-4" style={{height: "100%"}}>
                            <div className="card-header py-3">
                                <h6 className="m-0 font-weight-bold text-primary">VisibilityAction Table</h6>
                            </div>
                            <div className="card-body">
                                <div className="table-responsive" style={{height: "300px"}}>
                                <table className="table table-bordered table-striped table-hover" width="100%" cellSpacing="0">
                                        <thead>
                                            <tr>
                                                <th>Description</th>
                                                <th>Status</th>
                                                <th>State</th>
                                                <th>City</th>
                                                <th>Created date</th>
                                                <th>Action</th>
                                            </tr>
                                        </thead>
                                        <tbody style={{}}>
                                            {props.data.user?.visibilities?.map((row) => (
                                                <tr key={row.id}><td>{row.description}</td>
                                                    <td>
                                                        {row.status === 0? 
                                                            <span className="badge text-bg-success">Active</span>
                                                        : 
                                                            <span className="badge text-bg-danger">In Active</span>
                                                        }
                                                    </td>
                                                    <td>{row.state}</td>
                                                    <td>{row.city}</td>
                                                    <td>{timeTable(row.created_at)}</td>
                                                    <td>
                                                        <i onClick={() => {setRow(row, 'alert')}}  className="fas fa-edit text-success mr-2"></i>
                                                        <i onClick={() => {deleteRow(row, 'alert')}} className="fas fa-trash text-danger ml-2"></i>
                                                    </td>
                                                </tr>
                                            ))}
                                            

                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                        

                    </div>
                </div>
            : <></>}
            
        </>
    );

}

export default PostBannerMy