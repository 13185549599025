import React, { useEffect, useState, useRef } from 'react';

import Footer from "../components/Footer/Footer"
import Header from "../components/Header/Header";

import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, Link } from 'react-router-dom'
import {editUser, signUp} from '../routes/UserAction';
import CustomAlert from '../components/Alert/CustomAlert';

// import "./home.css"
import "./edit.css"
import PageHeaderTwo from "./PageHeaderTwo"
import PageHeader from "./PageHeader"

import ErrorDialog from '../components/Alert/ErrorDialog';
import PushNotification from '../components/Alert/PushNotification';
import MapSetter from '../components/Map/MapSetter';
import Locations from '../components/Location/Locations.json'
import { domainUrl, domain } from '../dummyData';
let url =  domain;

const EditProfilePage = () => {
  const { loading, user, error, success, successMessage } = useSelector((state) => state.persist.user)
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const errorDialogRef = useRef()
  const mapDialog = useRef()

  const [stage, setStage] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const handleRememberMe = () => {
    setFormData({...formData, rememberMe:!rememberMe});
  }

  const handleChangeUserType = (value) => {
    setFormData({...formData, userType:value });
    setStage(true)
  }

  const handleShowPassword = () => {
    setShowPassword(!showPassword);
  }

  const [errorMsg, setErrorMsg] = useState('');
  const customAlertRef = useRef()

  const [edit, setEdit] = useState(false);
  const [formData, setFormData] = useState({
    age: 0,
    updatePass: 0,
    map: 0,
    rememberMe: false,

    name: user.name, 
    image: user.image,
    voter_id: user.voter_id,
    email: user.email,
    phone: user.phone,
    password: '',
    oldpassword: '',
    lat: user.lat,
    long: user.long,
  });

  const handleChangeUpdatePass = () => {
    setFormData({...formData, updatePass:updatePass===0?1:0});
  }

  const handleMap = (value) => {
    // setFormData({...formData, map:map===0?1:0});
    if (value === 1) {
      mapDialog.current.handleOpen();
    }
    
  }

  const handleResponse = (value) => {
    if (value) {
      setFormData({...formData, lat:value.lat, long:value.lng });
    } else {
      setFormData({...formData, lat:'', long:'' });
    }
    
  }

  const {
    name, 
    voter_id, 
    email, 
    password, 
    oldpassword,
    updatePass,
    long, 
    lat, 
    phone,
    rememberMe
  } = formData;

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const clearData = () => {
    setFormData({ 
      ...formData, 
      name: user.name, 
      image: user.image, 
      voter_id: user.voter_id,
      email: user.email,
      phone: user.phone,
      password: '',
      lat: user.lat,
      long: user.long,
      rememberMe: false
    });
  };

  const [imagePreview, setImagePreview] = useState(null);
  const handleImageChange = (e) => {
    const files = e.target.files;
    if (files && files.length > 0) {
      const file = files[0];
      setFormData({ ...formData, image: file });
      setImagePreview(URL.createObjectURL(file));
    } else {
      setFormData({ ...formData, image: '' });
      setImagePreview(null);
      console.error("No file selected");
    }
  };

  useEffect(() => {
    if (!user) { 
      navigate('/login') 
    } else {

    }
  }, [navigate, user, success])

  const handleSubmit = (event) => {
    event.preventDefault();
    if (updatePass === 1) {
      if (password.length > 7 && oldpassword.length > 7) {
        if (
          email !== '' && 
          name !== '') {
            dispatch(editUser(formData))
            .then(response =>{
              if (error) {
                customAlertRef.current.handleOpen(error, 'danger');
              }else {
                if (successMessage) {
                  customAlertRef.current.handleOpen(successMessage, 'success');
                }
              }
            });
        } else {
          customAlertRef.current.handleOpen('Form filled incorrectly!', 'danger');
        }
      } else {
        customAlertRef.current.handleOpen('Please enter passwords correctly', 'danger');
      }
    } else {
      if (
        email !== '' &&
        name !== '') {
          dispatch(editUser(formData))
          .then(response =>{
            if (error) {
              customAlertRef.current.handleOpen(error, 'danger');
            }else {
              if (successMessage) {
                customAlertRef.current.handleOpen(successMessage, 'success');
              }
            }
          });
      } else {
        customAlertRef.current.handleOpen('Form filled incorrectly!', 'danger');
      }
    }

  };

  return (
    <>
      {/* reg */}
      <Header page={'player'}/>
      <div className="divider"></div>
      {/* <ErrorDialog ref={errorDialogRef} msg={errorMsg}/> */}
      <CustomAlert ref={customAlertRef} status={'danger'} msg={errorMsg}/>
      
      {loading ? (
        <div className="loader-container">
            <div className="spinner"></div>
            {/* <h6 style={{color: 'white', textAlign:'center'}}>loading please wait...</h6> */}
        </div>
      ) :
      <div style={{}} className=''>
        <div className="b-screen pb-4 overflow-y-auto" style={{backgroundColor:'white', height:'100%'}}>

          {/* <PageHeaderTwo screen={'big'}/> */}
          <div className="d-flex justify-content-center mb-5">
            <div className="rounded-4 d-flex flex-column justify-content-center my-auto pb-2" style=
            {{width:'40vw', height:'80%', backgroundColor:'#FFFFFF', border:"solid 2px #F8BC16"}}>

              {imagePreview ? 
              (
                <img 
                  style={{ border: '#16307D 3px solid', width: '10vw', height: '10vw', maxWidth: '30px', maxHeight: '30px' }} 
                  alt='Preview' 
                  src={imagePreview} 
                  className="rounded-circle mx-auto mt-2 mb-1" 
                />
              ) : 
              (
                user?.image ? (
                  <img 
                    style={{ border: '#16307D 3px solid', width: '10vw', height: '10vw', maxWidth: '30px', maxHeight: '30px' }} 
                    alt='ddk' 
                    src={user?.image ? url + user.image : "./assets/images/1.jpg"} 
                    className="rounded-circle mx-auto mt-2 mb-1" 
                  />
                ) : (
                  <img 
                    alt='ddk' 
                    src="./assets/images/logo/logo.png"
                    className="rounded-circle mx-auto mt-2 mb-1" 
                    height="50" 
                    width="50"
                  />
                )
              )
              }

              <h6 style={{color:'#16307D'}} className="mx-auto mt-0 mb-1">User Profile</h6>
              

              <form onSubmit={handleSubmit} className="d-flex flex-column justify-content-center my-auto mx-4">
                <div className="d-flex flex-row justify-content-center col-12 my-auto mx-auto">
                  <div className="input-group input-group-sm my-1 col-5">
                    <span style={{backgroundColor:'#F8BC16', border:"solid 1px #16307D"}} className="input-group-text text-white">
                      <i className="fa fa-edit"></i>Profile
                    </span>
                    <input onChange={handleImageChange} style={{backgroundColor:'#E1F2FE', border:"solid 1px #16307D", color:'#16307D'}} type="file" name="image" id="image" autoComplete="image" className="form-control" placeholder="Image"></input>
                  </div>
                </div>
                <div className='row'>
                  <div className='col-6'>
                    <div className="input-group input-group-sm my-1">
                      <span style={{backgroundColor:'#F8BC16', border:"solid 1px #16307D"}} className="input-group-text text-white">
                        <i className="fa fa-user"></i>
                      </span>
                      <input required onChange={handleChange} value={name} style={{backgroundColor:'#E1F2FE', border:"solid 1px #16307D", color:'#16307D'}} type="text" name="name" id="name" autoComplete="name" className="form-control" placeholder="Name"></input>
                    </div>
                  </div>
                  <div className='col-6'>
                    <div className="input-group input-group-sm my-1">
                      <span style={{backgroundColor:'#F8BC16', border:"solid 1px #16307D"}} className="input-group-text text-white">
                        <i className="fa fa-user"></i>
                      </span>
                      <input disabled onChange={handleChange} value={voter_id} style={{backgroundColor:'#E1F2FE', border:"solid 1px #16307D", color:'#16307D'}} type="text" name="voter_id" id="voter_id" autoComplete="voter_id" className="form-control" placeholder="Voter ID"></input>
                    </div>
                  </div>
                </div>

                <div className='row'>
                  <div className='col-6'>
                    <div className="input-group input-group-sm my-1">
                      <span style={{backgroundColor:'#F8BC16', border:"solid 1px #16307D"}} className="input-group-text text-white">
                        <i className="fa fa-envelope"></i>
                      </span>
                      <input required onChange={handleChange} value={email} style={{backgroundColor:'#E1F2FE', border:"solid 1px #16307D", color:'#16307D'}} type="email" name="email" id="email" autoComplete="email" className="form-control" placeholder="Email"></input>
                    </div>
                  </div>
                  <div className='col-6'>
                    <div className="input-group input-group-sm my-1">
                      <span style={{backgroundColor:'#F8BC16', border:"solid 1px #16307D"}} className="input-group-text text-white">
                        <i className="fa fa-location"></i>
                      </span>
                      <input required onChange={handleChange} value={phone} style={{backgroundColor:'#E1F2FE', border:"solid 1px #16307D", color:'#16307D'}} type="text" name="phone" id="phone" autoComplete="phone" className="form-control" placeholder="phone"></input>
                    </div>
                  </div>
                </div>
                

                <div className="form-check my-0 mx-auto ">
                  <input style={{backgroundColor:'#F8BC16', color:'#16307D'}} className="form-check-input mt-0" type="checkbox" id="remember_me" checked={updatePass === 0? false: true} onChange={handleChangeUpdatePass} value={updatePass}/>
                  <h6 className='my-0' style={{color:'#16307D', fontSize:11}}>Change Password</h6>
                </div>

                {updatePass === 1? 
                <div className='row'>
                  <div className="input-group col-6 input-group-sm my-1">
                    <span style={{backgroundColor:'#F8BC16', border:"solid 1px #16307D"}} className="input-group-text text-white">
                      <i className="fa fa-lock"></i>
                    </span>
                    <input onChange={handleChange} value={oldpassword} style={{backgroundColor:'#E1F2FE', border:"solid 1px #16307D", color:'#16307D'}} type={showPassword ? "text" : "password"} name="oldpassword" id="oldpassword" className="form-control" placeholder="Enter Password"></input>
                    <span style={{backgroundColor:'#F8BC16', border:"solid 1px #16307D"}} className="input-group-text text-white">
                      {showPassword?<i onClick={handleShowPassword} className="fa fa-eye-slash"></i>
                      :<i onClick={handleShowPassword} className="fa fa-eye"></i>}
                    </span>
                  </div>
                  <div className="input-group input-group-sm col-6 my-1">
                    <span style={{backgroundColor:'#F8BC16', border:"solid 1px #16307D"}} className="input-group-text text-white">
                      <i className="fa fa-lock"></i>
                    </span>
                    <input onChange={handleChange} value={password} style={{backgroundColor:'#E1F2FE', border:"solid 1px #16307D", color:'#16307D'}} type={showPassword ? "text" : "password"} name="password" id="password" className="form-control" placeholder="Enter New Password"></input>
                    <span style={{backgroundColor:'#F8BC16', border:"solid 1px #16307D"}} className="input-group-text text-white">
                      {showPassword?<i onClick={handleShowPassword} className="fa fa-eye-slash"></i>
                      :<i onClick={handleShowPassword} className="fa fa-eye"></i>}
                    </span>
                  </div>
                </div>
                
                :null}
                

                <button 
                  style={{color:'#FFFFFF', backgroundColor:'#F8BC16', width:'120px', border:"solid 1px #16307D"}} type="submit" className="btn btn-sm rounded-4 mx-auto">
                    Save
                  </button>
              </form>
            
            </div>
          </div>

        </div>

        {/* mobile */}
        <div className="m-screen pb-2 overflow-y-auto" style={{backgroundColor:'white', height:'100%'}}>

          {/* <PageHeaderTwo screen={'mobile'}/> */}


          <div className="d-flex justify-content-center my-5 px-3">
            <div className="rounded-4 d-flex flex-column justify-content-center py-2 mx-auto" style={{width:'100%', height:'80%', backgroundColor:'#FFFFFF', border:"solid 2px #F8BC16"}}>

              {imagePreview ? 
              (
                <img 
                  style={{ border: '#16307D 3px solid', width: '10vw', height: '10vw', maxWidth: '30px', maxHeight: '30px' }} 
                  alt='Preview' 
                  src={imagePreview} 
                  className="rounded-circle mx-auto mt-2 mb-1" 
                />
              ) : 
              (
                user?.image ? (
                  <img 
                    style={{ border: '#16307D 3px solid', width: '10vw', height: '10vw', maxWidth: '30px', maxHeight: '30px' }} 
                    alt='ddk' 
                    src={user?.image ? url + user.image : "./assets/images/1.jpg"} 
                    className="rounded-circle mx-auto mt-2 mb-1" 
                  />
                ) : (
                  <img 
                    alt='ddk' 
                    src="./assets/images/logo/logo.png"
                    className="rounded-circle mx-auto mt-2 mb-1" 
                    height="50" 
                    width="50"
                  />
                )
              )
              }


              <h6 style={{color:'#16307D'}} className="mx-auto mt-0 mb-1">User Profile</h6>
              

              <form onSubmit={handleSubmit} className="d-flex flex-column justify-content-center my-auto mx-3">
                <div className="d-flex flex-row justify-content-center col-12 my-auto mx-auto">
                  <div className="input-group input-group-sm my-1 col-6">
                    <span style={{backgroundColor:'#F8BC16', border:"solid 1px #16307D"}} className="input-group-text text-white">
                      <i className="fa fa-edit"></i>Profile
                    </span>
                    <input onChange={handleImageChange} style={{backgroundColor:'#E1F2FE', border:"solid 1px #16307D", color:'#16307D'}} type="file" name="image" id="image" autoComplete="image" className="form-control" placeholder="Image"></input>
                  </div>
                </div>
                <div className='row'>
                  <div className='col-12'>
                    <div className="input-group input-group-sm my-1">
                      <span style={{backgroundColor:'#F8BC16', border:"solid 1px #16307D"}} className="input-group-text text-white">
                        <i className="fa fa-user"></i>
                      </span>
                      <input required onChange={handleChange} value={name} style={{backgroundColor:'#E1F2FE', border:"solid 1px #16307D", color:'#16307D'}} type="text" name="name" id="name" autoComplete="name" className="form-control" placeholder="Name"></input>
                    </div>
                  </div>
                  <div className='col-12'>
                    <div className="input-group input-group-sm my-1">
                      <span style={{backgroundColor:'#F8BC16', border:"solid 1px #16307D"}} className="input-group-text text-white">
                        <i className="fa fa-user"></i>
                      </span>
                      <input disabled onChange={handleChange} value={voter_id} style={{backgroundColor:'#E1F2FE', border:"solid 1px #16307D", color:'#16307D'}} type="text" name="voter_id" id="voter_id" autoComplete="voter_id" className="form-control" placeholder="Voter ID"></input>
                    </div>
                  </div>
                </div>

                <div className='row'>
                  <div className='col-12'>
                    <div className="input-group input-group-sm my-1">
                      <span style={{backgroundColor:'#F8BC16', border:"solid 1px #16307D"}} className="input-group-text text-white">
                        <i className="fa fa-envelope"></i>
                      </span>
                      <input required onChange={handleChange} value={email} style={{backgroundColor:'#E1F2FE', border:"solid 1px #16307D", color:'#16307D'}} type="email" name="email" id="email" autoComplete="email" className="form-control" placeholder="Email"></input>
                    </div>
                  </div>
                  <div className='col-12'>
                    <div className="input-group input-group-sm my-1">
                      <span style={{backgroundColor:'#F8BC16', border:"solid 1px #16307D"}} className="input-group-text text-white">
                        <i className="fa fa-location"></i>
                      </span>
                      <input required onChange={handleChange} value={phone} style={{backgroundColor:'#E1F2FE', border:"solid 1px #16307D", color:'#16307D'}} type="text" name="phone" id="phone" autoComplete="phone" className="form-control" placeholder="phone"></input>
                    </div>
                  </div>
                </div>

                <div className="form-check my-0 mx-auto ">
                  <input style={{backgroundColor:'#F8BC16', color:'#16307D'}} className="form-check-input mt-0" type="checkbox" id="remember_me" checked={updatePass === 0? false: true} onChange={handleChangeUpdatePass} value={updatePass}/>
                  <h6 className='my-0' style={{color:'#16307D', fontSize:11}}>Change password</h6>
                </div>

                {updatePass === 1?
                  <div className='row'>
                    <div className="input-group input-group-sm my-1 col-6">
                      <span style={{backgroundColor:'#F8BC16', border:"solid 1px #16307D"}} className="input-group-text text-white">
                        <i className="fa fa-lock"></i>
                      </span>
                      <input onChange={handleChange} value={oldpassword} style={{backgroundColor:'#E1F2FE', border:"solid 1px #16307D", color:'#16307D'}} type={showPassword ? "text" : "password"} name="oldpassword" id="oldpassword" className="form-control" placeholder="Password"></input>
                      <span style={{backgroundColor:'#F8BC16', border:"solid 1px #16307D"}} className="input-group-text text-white">
                        {showPassword?<i onClick={handleShowPassword} className="fa fa-eye-slash"></i>
                        :<i onClick={handleShowPassword} className="fa fa-eye"></i>}
                      </span>
                    </div>
                    <div className="input-group input-group-sm my-1 col-6">
                      <span style={{backgroundColor:'#F8BC16', border:"solid 1px #16307D"}} className="input-group-text text-white">
                        <i className="fa fa-lock"></i>
                      </span>
                      <input onChange={handleChange} value={password} style={{backgroundColor:'#E1F2FE', border:"solid 1px #16307D", color:'#16307D'}} type={showPassword ? "text" : "password"} name="password" id="password" className="form-control" placeholder="New Password"></input>
                      <span style={{backgroundColor:'#F8BC16', border:"solid 1px #16307D"}} className="input-group-text text-white">
                        {showPassword?<i onClick={handleShowPassword} className="fa fa-eye-slash"></i>
                        :<i onClick={handleShowPassword} className="fa fa-eye"></i>}
                      </span>
                    </div>
                  </div>
                :
                  null
                }

                

                <button 
                  style={{color:'#FFFFFF', backgroundColor:'#F8BC16', width:'120px', border:"solid 1px #16307D"}} type="submit" className="btn btn-sm rounded-4 mx-auto">
                    Save
                </button>
              </form>
            
            </div>
          </div>

        </div>
      </div>
      }
      <Footer/>
    </>
  )
}

export default EditProfilePage
