import React, { useState, useEffect } from "react";
import PostBanner from "../../components/Banners/PostBanner";
import PostBannerMy from "../../components/Banners/PostBannerMy";
import SlickHorizontalScroll from "../../components/Horizontal/SlickHorizontalScroll";
import Footer from "../../components/Footer/Footer"
import Header from "../../components/Header/Header";

import PostCard from "../../components/Cards/PostCard";

import { Link, useNavigate, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux'
import { getPosts, showPost } from '../../routes/UserAction';


const UserPost = (props) => {

    const search = useLocation().search;
    const id =new URLSearchParams(search).get("id");
    // const userId =new URLSearchParams(search).get("code");

    const dispatch = useDispatch();
    const { user, searchWord, loading } = useSelector((state) => state.persist.user)
    // const [loading, setLoading] = useState(false);
    const userData = user;

    // const sessionSli = useSelector((state) => state.session)
    // const sLoading = sessionSli.loading;
    // const chatSli = useSelector((state) => state.chat)
    // const cLoading = chatSli.loading;
    // const sysSli = useSelector((state) => state.system)
    // const sysLoading = sysSli.loading;

    const [postData, setPostData] = useState([]);
    const [postsData, setPostsData] = useState([]);
    const [isMine, setIsMine] = useState(false);

    const [pageNo, setPageNo] = useState(1);
    const handleLoadPlus = () => { 
        setPageNo(pageNo+1); 
    };
    const handleLoadMinus = () => { 
        setPageNo(pageNo >1? pageNo-1: 1); 
    };

    const fetchPost = () => {
        dispatch(showPost(id))
        .then(response =>{
          let resData = response.payload.data;
          setPostData(resData);

          if (userData.id === response.payload.data?.user?.id) {
            setIsMine(true)
          }
        })
        .catch((error) => {
            console.log(error)
        })
    }

    const fetchPosts = () => {
        dispatch(getPosts({cat: 'single', searchword: searchWord, page:pageNo}))
        .then(response =>{
        //   let resData = response.payload.data.filter(el => el.uuid !== id);
          let resData = response.payload.data;
          const editableData = resData.map(o => ({ ...o }));
          setPostsData(editableData);
        })
        .catch((error) => {
            console.log(error)
        })
    }

    useEffect(() => {

        fetchPosts();
        fetchPost();
    
    }, [id])

    return(
        <>
            <div className="divider"></div>
            <Header page={'post'}/>

            {/* {(loading || sLoading || cLoading || sysLoading) ? ( */}
            {(loading) ? (
                <div className="loader-container">
                    <div className="spinner"></div>
                    {/* <h6 style={{color: 'white', textAlign:'center'}}>loading please wait...</h6> */}
                </div>
            ) :<>
            
                {isMine === true? <PostBannerMy data={postData} fetchPost={fetchPost}/> : <PostBanner data={postData}/>}

                {/* <div className="d-flex justify-content-center my-2">
                    <div className="d-flex flex-row">
                        {postData.expertise?.length > 0 ?
                            postData.expertise?.map((row) => (
                                <span key={row.id} style={{marginRight:'5px', backgroundColor:'#E1F2FE', color:'#16307D', border:"solid 1px #16307D"}} className="badge py-2 my-1 rounded-pill">{row.expertise.name}</span>
                            ))
                        : <h5 className="my-3" style={{color:'#16307D', fontSize: 12}}>No Expertise...</h5>}
                    </div>
                </div> */}

                <h6 style={{textAlign:'center', color:'#16307D', marginTop:'1px', marginBottom:'0', marginLeft:"4px"}}>{user.description}</h6>

                {isMine === true? <></> :
                <div>
                    <div className="row my-3" style={{marginRight:'5px', marginLeft:'20px', color:'#16307D'}}>
                        Similar Posts
                        {/* <span style={{marginRight:'5px', backgroundColor:'#E1F2FE', color:'#16307D', border:"solid 1px #16307D"}} className="badge py-2 my-1 rounded-pill">Passing</span> */}
                        <hr className="col-10" style={{color:'#16307D', backgroundColor:'#16307D', marginRight:'12px', marginLeft:'5px', height:'2px', marginTop:'12px', marginBottom:'0'}}/>
                    </div>

                    <div className="px-5">
                        <SlickHorizontalScroll slidesToShow={postsData.length<=3? (postsData.length) : null} data={postsData} card={PostCard}/>
                        
                    </div>
                </div>
                }
                
            </>}

            <Footer/>
        </>
        
    )
}

export default UserPost